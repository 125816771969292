/* Fonts */

@import url('https://fonts.googleapis.com/css?family=Exo+2:300,400,600,700,800&subset=cyrillic,latin-ext');

/* --- Common styles --- */

html {
    font-size: 100%;
	height: 100%;
}

body {
	color: #2e3239;
	font-family: 'Exo 2', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.35;
	background: #fff;	
	min-width: 320px;
}

* {
    box-sizing: border-box;
}
a {
	color: #3a90d9;
	outline: 0 none;
	text-decoration: underline;
	&:hover {
		color: #4674cc;
	}
	&:focus {
		text-decoration: none;
		outline: 0 none;
	}
}
.g-dashed-link {
	text-decoration: none;
	border-bottom: 1px dashed;
	&:hover {
	
	}
}
h1, h2, h3, h4 {
	text-transform: uppercase;
	line-height: 1.2;
}
b {
	font-weight: 600;
}
img {
	width: auto;
	max-width: 100%;
}
input[type="text"],
input[type="email"],
textarea {
	width: 100%;
	color: #586378;
	font-family: 'Exo 2', sans-serif;
	font-size: 1em;
	font-weight: 400;
	background: #fff;
	padding: 9.5px 15px;
	border: none;
	border: 1px solid #fff;
	@include border-radius(5px);
	@include box-shadow(inset,0,2px,2px,rgba(0,0,0,0.1));
	outline: 0 none;
}
input,
button,
input[type="text"],
input[type="email"],
textarea {
	outline: 0 none;
}
::-webkit-input-placeholder {color: #adb3c1;}
::-moz-placeholder {color: #adb3c1;}
:-ms-input-placeholder {color: #adb3c1;}


/* --- Layout --- */

.l-wrapper {	
	width: 1170px;
	margin: 0 auto;
	@include breakpoint(large) {
		width: 960px;
	}
	@include breakpoint(medium) {
		width: 720px;
		padding: 0 20px;
	}
	@include breakpoint(small) {
		width: 580px;
		padding: 0 10px;
	}
	@include breakpoint(xsmall) {
		width: 100%;				
	}
}

.l-overlay {
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	background: url("../images/page-overlay-bg.png") repeat;
	background: rgba(0,0,0,0.9);
	top: 0;
	left: 0;
	z-index: 98;
}
.l-preloader {
	background: #586378;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	.g-preloader {
		display: block;
		width: 100px;
		height: 100px;
		top: 50%;
		left: 50%;
		margin: -50px 0 0 -50px;
		position: absolute;
		z-index: 100;
	}
}


/* --- Main styles --- */

.g-anchor-pos {
	display: block;
	content: "";
	height: 0;
}
.g-hidden-b {
	display: none;
}


/* --- Buttons --- */

.btn-wrapper {
	display: block;	
}
.button,
.btn {
	color: #fff;
	display: inline-block;
	cursor: pointer;
	font-family: 'Exo 2', sans-serif;
	font-size: 1em;
	font-weight: 700;
	line-height: 100%;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	padding: 13px 30px;
	border: none;
	background: #556074;
	border-bottom: 3px solid #3a4250;	
	@include border-radius(5px);
	&:hover {
		color: #fff;
		background: #657084;
	}
	&:active {
		margin-top: 3px;
		border-bottom: none;
		@include box-shadow(inset, 0, 5px, 5px, rgba(0,0,0,0.15));
	}
	&.btn-green {
		background: #3fa766;
		border-bottom-color: #0b6f31;
		&:hover {
			background: #53b076;
		}
	}
}

/* --- Multiselect --- */

.ms-options-wrap,
.ms-options-wrap * {
    box-sizing: border-box;
}

.ms-options-wrap > button:focus,
.ms-options-wrap > button { 
	width: 100%;
   	color: #adb3c1;
	font-family: 'Exo 2', sans-serif;
	font-size: 1em;
	font-weight: 400;
	background: #fff;
	padding: 9.5px 15px;
	border: none;
	border: 1px solid #fff;
	@include border-radius(5px);
	@include box-shadow(inset,0,2px,2px,rgba(0,0,0,0.1));
	outline: 0 none;
	position: relative;
	text-align: left;
    white-space: nowrap;
}

.ms-options-wrap.g-filled > button {
	color: #586378;
}

.ms-options-wrap > button[disabled] {
    background-color: #e5e9ed;
    color: #808080;
    opacity: 0.6;
}

.ms-options-wrap > button:after {
    content: ' ';
    height: 0;
    position: absolute;
    top: 50%;
    right: 15px;
    width: 0;
    border: 6px solid rgba(0, 0, 0, 0);
    border-top-color: #586378;
    margin-top: -3px;
}

.ms-options-wrap.g-opened > button:after {
	border-top-color: rgba(0, 0, 0, 0);
	border-bottom-color: #586378;
	margin-top: -9px;
}

.ms-options-wrap > .ms-options {
    position: absolute;
    left: 0;
    width: 100%;
    margin-top: 1px;
  	padding: 12px;
    background: #fff;
    z-index: 99;
	@include border-radius(5px);
    @include box-shadow(0,5px,5px,rgba(0,0,0,0.15));
}
.ms-options-wrap > .ms-options > .ms-search input {
    width: 100%;
    padding: 4px 5px;
    border: none;
    border-bottom: 1px groove;
    outline: none;
}

.ms-options-wrap > .ms-options .ms-selectall {
    display: inline-block;
    font-size: .9em;
    text-transform: lowercase;
    text-decoration: none;
}
.ms-options-wrap > .ms-options .ms-selectall:hover {
    text-decoration: underline;
}

.ms-options-wrap > .ms-options > .ms-selectall.global {
    margin: 4px 5px;
}

.ms-options-wrap > .ms-options > ul,
.ms-options-wrap > .ms-options > ul > li.optgroup ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ms-options-wrap > .ms-options > ul > li.optgroup {
    padding: 5px;
}
.ms-options-wrap > .ms-options > ul > li.optgroup + li.optgroup {
    border-top: 1px solid #aaa;
}

.ms-options-wrap > .ms-options > ul > li.optgroup .label {
    display: block;
    padding: 5px 0 0 0;
    font-weight: bold;
}

.ms-options-wrap > .ms-options > ul label {
    position: relative;
    display: inline-block;
	color: #586378;
	font-size: 0.875em;
	font-weight: 600;
	line-height: 1.25;
    width: 100%;
    padding: 4px;
    margin: 1px 0;
	@include border-radius(3px);
	@include transition(background 0.25s);
}

.ms-options-wrap > .ms-options > ul label.focused,
.ms-options-wrap > .ms-options > ul label:hover {
    background-color: #efefef;
}

.ms-options-wrap > .ms-options > ul li.selected label {
	color: #fff;
    background-color: #586378;
}

.ms-options-wrap > .ms-options > ul input[type="checkbox"] {
    margin: 0 5px 0 0;
    position: absolute;
    left: 4px;
    top: 6px;
}

.ms-options-wrap > .ms-options.hide-checkbox > ul input[type="checkbox"] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
}


/* --- Slick slider --- */

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}
.slick-slider * {
	outline: 0 none;
}
.slick-list {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
}
.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@charset "UTF-8";
/* Vendor */
/* Setup */
/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
@import url("https://fonts.googleapis.com/css?family=Exo+2:300,400,600,700,800&subset=cyrillic,latin-ext");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { border: 0; font-size: 100%; font: inherit; vertical-align: baseline; margin: 0; padding: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }

body { line-height: 1; }

ol, ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: none; }

table { border-collapse: collapse; border-spacing: 0; }

[data-aos][data-aos][data-aos-duration='50'], body[data-aos-duration='50'] [data-aos] { transition-duration: 50ms; }

[data-aos][data-aos][data-aos-delay='50'], body[data-aos-delay='50'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='50'].aos-animate, body[data-aos-delay='50'] [data-aos].aos-animate { transition-delay: 50ms; }

[data-aos][data-aos][data-aos-duration='100'], body[data-aos-duration='100'] [data-aos] { transition-duration: .1s; }

[data-aos][data-aos][data-aos-delay='100'], body[data-aos-delay='100'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='100'].aos-animate, body[data-aos-delay='100'] [data-aos].aos-animate { transition-delay: .1s; }

[data-aos][data-aos][data-aos-duration='150'], body[data-aos-duration='150'] [data-aos] { transition-duration: .15s; }

[data-aos][data-aos][data-aos-delay='150'], body[data-aos-delay='150'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='150'].aos-animate, body[data-aos-delay='150'] [data-aos].aos-animate { transition-delay: .15s; }

[data-aos][data-aos][data-aos-duration='200'], body[data-aos-duration='200'] [data-aos] { transition-duration: .2s; }

[data-aos][data-aos][data-aos-delay='200'], body[data-aos-delay='200'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='200'].aos-animate, body[data-aos-delay='200'] [data-aos].aos-animate { transition-delay: .2s; }

[data-aos][data-aos][data-aos-duration='250'], body[data-aos-duration='250'] [data-aos] { transition-duration: .25s; }

[data-aos][data-aos][data-aos-delay='250'], body[data-aos-delay='250'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='250'].aos-animate, body[data-aos-delay='250'] [data-aos].aos-animate { transition-delay: .25s; }

[data-aos][data-aos][data-aos-duration='300'], body[data-aos-duration='300'] [data-aos] { transition-duration: .3s; }

[data-aos][data-aos][data-aos-delay='300'], body[data-aos-delay='300'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='300'].aos-animate, body[data-aos-delay='300'] [data-aos].aos-animate { transition-delay: .3s; }

[data-aos][data-aos][data-aos-duration='350'], body[data-aos-duration='350'] [data-aos] { transition-duration: .35s; }

[data-aos][data-aos][data-aos-delay='350'], body[data-aos-delay='350'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='350'].aos-animate, body[data-aos-delay='350'] [data-aos].aos-animate { transition-delay: .35s; }

[data-aos][data-aos][data-aos-duration='400'], body[data-aos-duration='400'] [data-aos] { transition-duration: .4s; }

[data-aos][data-aos][data-aos-delay='400'], body[data-aos-delay='400'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='400'].aos-animate, body[data-aos-delay='400'] [data-aos].aos-animate { transition-delay: .4s; }

[data-aos][data-aos][data-aos-duration='450'], body[data-aos-duration='450'] [data-aos] { transition-duration: .45s; }

[data-aos][data-aos][data-aos-delay='450'], body[data-aos-delay='450'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='450'].aos-animate, body[data-aos-delay='450'] [data-aos].aos-animate { transition-delay: .45s; }

[data-aos][data-aos][data-aos-duration='500'], body[data-aos-duration='500'] [data-aos] { transition-duration: .5s; }

[data-aos][data-aos][data-aos-delay='500'], body[data-aos-delay='500'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='500'].aos-animate, body[data-aos-delay='500'] [data-aos].aos-animate { transition-delay: .5s; }

[data-aos][data-aos][data-aos-duration='550'], body[data-aos-duration='550'] [data-aos] { transition-duration: .55s; }

[data-aos][data-aos][data-aos-delay='550'], body[data-aos-delay='550'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='550'].aos-animate, body[data-aos-delay='550'] [data-aos].aos-animate { transition-delay: .55s; }

[data-aos][data-aos][data-aos-duration='600'], body[data-aos-duration='600'] [data-aos] { transition-duration: .6s; }

[data-aos][data-aos][data-aos-delay='600'], body[data-aos-delay='600'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='600'].aos-animate, body[data-aos-delay='600'] [data-aos].aos-animate { transition-delay: .6s; }

[data-aos][data-aos][data-aos-duration='650'], body[data-aos-duration='650'] [data-aos] { transition-duration: .65s; }

[data-aos][data-aos][data-aos-delay='650'], body[data-aos-delay='650'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='650'].aos-animate, body[data-aos-delay='650'] [data-aos].aos-animate { transition-delay: .65s; }

[data-aos][data-aos][data-aos-duration='700'], body[data-aos-duration='700'] [data-aos] { transition-duration: .7s; }

[data-aos][data-aos][data-aos-delay='700'], body[data-aos-delay='700'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='700'].aos-animate, body[data-aos-delay='700'] [data-aos].aos-animate { transition-delay: .7s; }

[data-aos][data-aos][data-aos-duration='750'], body[data-aos-duration='750'] [data-aos] { transition-duration: .75s; }

[data-aos][data-aos][data-aos-delay='750'], body[data-aos-delay='750'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='750'].aos-animate, body[data-aos-delay='750'] [data-aos].aos-animate { transition-delay: .75s; }

[data-aos][data-aos][data-aos-duration='800'], body[data-aos-duration='800'] [data-aos] { transition-duration: .8s; }

[data-aos][data-aos][data-aos-delay='800'], body[data-aos-delay='800'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='800'].aos-animate, body[data-aos-delay='800'] [data-aos].aos-animate { transition-delay: .8s; }

[data-aos][data-aos][data-aos-duration='850'], body[data-aos-duration='850'] [data-aos] { transition-duration: .85s; }

[data-aos][data-aos][data-aos-delay='850'], body[data-aos-delay='850'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='850'].aos-animate, body[data-aos-delay='850'] [data-aos].aos-animate { transition-delay: .85s; }

[data-aos][data-aos][data-aos-duration='900'], body[data-aos-duration='900'] [data-aos] { transition-duration: .9s; }

[data-aos][data-aos][data-aos-delay='900'], body[data-aos-delay='900'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='900'].aos-animate, body[data-aos-delay='900'] [data-aos].aos-animate { transition-delay: .9s; }

[data-aos][data-aos][data-aos-duration='950'], body[data-aos-duration='950'] [data-aos] { transition-duration: .95s; }

[data-aos][data-aos][data-aos-delay='950'], body[data-aos-delay='950'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='950'].aos-animate, body[data-aos-delay='950'] [data-aos].aos-animate { transition-delay: .95s; }

[data-aos][data-aos][data-aos-duration='1000'], body[data-aos-duration='1000'] [data-aos] { transition-duration: 1s; }

[data-aos][data-aos][data-aos-delay='1000'], body[data-aos-delay='1000'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1000'].aos-animate, body[data-aos-delay='1000'] [data-aos].aos-animate { transition-delay: 1s; }

[data-aos][data-aos][data-aos-duration='1050'], body[data-aos-duration='1050'] [data-aos] { transition-duration: 1.05s; }

[data-aos][data-aos][data-aos-delay='1050'], body[data-aos-delay='1050'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1050'].aos-animate, body[data-aos-delay='1050'] [data-aos].aos-animate { transition-delay: 1.05s; }

[data-aos][data-aos][data-aos-duration='1100'], body[data-aos-duration='1100'] [data-aos] { transition-duration: 1.1s; }

[data-aos][data-aos][data-aos-delay='1100'], body[data-aos-delay='1100'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1100'].aos-animate, body[data-aos-delay='1100'] [data-aos].aos-animate { transition-delay: 1.1s; }

[data-aos][data-aos][data-aos-duration='1150'], body[data-aos-duration='1150'] [data-aos] { transition-duration: 1.15s; }

[data-aos][data-aos][data-aos-delay='1150'], body[data-aos-delay='1150'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1150'].aos-animate, body[data-aos-delay='1150'] [data-aos].aos-animate { transition-delay: 1.15s; }

[data-aos][data-aos][data-aos-duration='1200'], body[data-aos-duration='1200'] [data-aos] { transition-duration: 1.2s; }

[data-aos][data-aos][data-aos-delay='1200'], body[data-aos-delay='1200'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1200'].aos-animate, body[data-aos-delay='1200'] [data-aos].aos-animate { transition-delay: 1.2s; }

[data-aos][data-aos][data-aos-duration='1250'], body[data-aos-duration='1250'] [data-aos] { transition-duration: 1.25s; }

[data-aos][data-aos][data-aos-delay='1250'], body[data-aos-delay='1250'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1250'].aos-animate, body[data-aos-delay='1250'] [data-aos].aos-animate { transition-delay: 1.25s; }

[data-aos][data-aos][data-aos-duration='1300'], body[data-aos-duration='1300'] [data-aos] { transition-duration: 1.3s; }

[data-aos][data-aos][data-aos-delay='1300'], body[data-aos-delay='1300'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1300'].aos-animate, body[data-aos-delay='1300'] [data-aos].aos-animate { transition-delay: 1.3s; }

[data-aos][data-aos][data-aos-duration='1350'], body[data-aos-duration='1350'] [data-aos] { transition-duration: 1.35s; }

[data-aos][data-aos][data-aos-delay='1350'], body[data-aos-delay='1350'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1350'].aos-animate, body[data-aos-delay='1350'] [data-aos].aos-animate { transition-delay: 1.35s; }

[data-aos][data-aos][data-aos-duration='1400'], body[data-aos-duration='1400'] [data-aos] { transition-duration: 1.4s; }

[data-aos][data-aos][data-aos-delay='1400'], body[data-aos-delay='1400'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1400'].aos-animate, body[data-aos-delay='1400'] [data-aos].aos-animate { transition-delay: 1.4s; }

[data-aos][data-aos][data-aos-duration='1450'], body[data-aos-duration='1450'] [data-aos] { transition-duration: 1.45s; }

[data-aos][data-aos][data-aos-delay='1450'], body[data-aos-delay='1450'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1450'].aos-animate, body[data-aos-delay='1450'] [data-aos].aos-animate { transition-delay: 1.45s; }

[data-aos][data-aos][data-aos-duration='1500'], body[data-aos-duration='1500'] [data-aos] { transition-duration: 1.5s; }

[data-aos][data-aos][data-aos-delay='1500'], body[data-aos-delay='1500'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1500'].aos-animate, body[data-aos-delay='1500'] [data-aos].aos-animate { transition-delay: 1.5s; }

[data-aos][data-aos][data-aos-duration='1550'], body[data-aos-duration='1550'] [data-aos] { transition-duration: 1.55s; }

[data-aos][data-aos][data-aos-delay='1550'], body[data-aos-delay='1550'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1550'].aos-animate, body[data-aos-delay='1550'] [data-aos].aos-animate { transition-delay: 1.55s; }

[data-aos][data-aos][data-aos-duration='1600'], body[data-aos-duration='1600'] [data-aos] { transition-duration: 1.6s; }

[data-aos][data-aos][data-aos-delay='1600'], body[data-aos-delay='1600'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1600'].aos-animate, body[data-aos-delay='1600'] [data-aos].aos-animate { transition-delay: 1.6s; }

[data-aos][data-aos][data-aos-duration='1650'], body[data-aos-duration='1650'] [data-aos] { transition-duration: 1.65s; }

[data-aos][data-aos][data-aos-delay='1650'], body[data-aos-delay='1650'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1650'].aos-animate, body[data-aos-delay='1650'] [data-aos].aos-animate { transition-delay: 1.65s; }

[data-aos][data-aos][data-aos-duration='1700'], body[data-aos-duration='1700'] [data-aos] { transition-duration: 1.7s; }

[data-aos][data-aos][data-aos-delay='1700'], body[data-aos-delay='1700'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1700'].aos-animate, body[data-aos-delay='1700'] [data-aos].aos-animate { transition-delay: 1.7s; }

[data-aos][data-aos][data-aos-duration='1750'], body[data-aos-duration='1750'] [data-aos] { transition-duration: 1.75s; }

[data-aos][data-aos][data-aos-delay='1750'], body[data-aos-delay='1750'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1750'].aos-animate, body[data-aos-delay='1750'] [data-aos].aos-animate { transition-delay: 1.75s; }

[data-aos][data-aos][data-aos-duration='1800'], body[data-aos-duration='1800'] [data-aos] { transition-duration: 1.8s; }

[data-aos][data-aos][data-aos-delay='1800'], body[data-aos-delay='1800'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1800'].aos-animate, body[data-aos-delay='1800'] [data-aos].aos-animate { transition-delay: 1.8s; }

[data-aos][data-aos][data-aos-duration='1850'], body[data-aos-duration='1850'] [data-aos] { transition-duration: 1.85s; }

[data-aos][data-aos][data-aos-delay='1850'], body[data-aos-delay='1850'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1850'].aos-animate, body[data-aos-delay='1850'] [data-aos].aos-animate { transition-delay: 1.85s; }

[data-aos][data-aos][data-aos-duration='1900'], body[data-aos-duration='1900'] [data-aos] { transition-duration: 1.9s; }

[data-aos][data-aos][data-aos-delay='1900'], body[data-aos-delay='1900'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1900'].aos-animate, body[data-aos-delay='1900'] [data-aos].aos-animate { transition-delay: 1.9s; }

[data-aos][data-aos][data-aos-duration='1950'], body[data-aos-duration='1950'] [data-aos] { transition-duration: 1.95s; }

[data-aos][data-aos][data-aos-delay='1950'], body[data-aos-delay='1950'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1950'].aos-animate, body[data-aos-delay='1950'] [data-aos].aos-animate { transition-delay: 1.95s; }

[data-aos][data-aos][data-aos-duration='2000'], body[data-aos-duration='2000'] [data-aos] { transition-duration: 2s; }

[data-aos][data-aos][data-aos-delay='2000'], body[data-aos-delay='2000'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2000'].aos-animate, body[data-aos-delay='2000'] [data-aos].aos-animate { transition-delay: 2s; }

[data-aos][data-aos][data-aos-duration='2050'], body[data-aos-duration='2050'] [data-aos] { transition-duration: 2.05s; }

[data-aos][data-aos][data-aos-delay='2050'], body[data-aos-delay='2050'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2050'].aos-animate, body[data-aos-delay='2050'] [data-aos].aos-animate { transition-delay: 2.05s; }

[data-aos][data-aos][data-aos-duration='2100'], body[data-aos-duration='2100'] [data-aos] { transition-duration: 2.1s; }

[data-aos][data-aos][data-aos-delay='2100'], body[data-aos-delay='2100'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2100'].aos-animate, body[data-aos-delay='2100'] [data-aos].aos-animate { transition-delay: 2.1s; }

[data-aos][data-aos][data-aos-duration='2150'], body[data-aos-duration='2150'] [data-aos] { transition-duration: 2.15s; }

[data-aos][data-aos][data-aos-delay='2150'], body[data-aos-delay='2150'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2150'].aos-animate, body[data-aos-delay='2150'] [data-aos].aos-animate { transition-delay: 2.15s; }

[data-aos][data-aos][data-aos-duration='2200'], body[data-aos-duration='2200'] [data-aos] { transition-duration: 2.2s; }

[data-aos][data-aos][data-aos-delay='2200'], body[data-aos-delay='2200'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2200'].aos-animate, body[data-aos-delay='2200'] [data-aos].aos-animate { transition-delay: 2.2s; }

[data-aos][data-aos][data-aos-duration='2250'], body[data-aos-duration='2250'] [data-aos] { transition-duration: 2.25s; }

[data-aos][data-aos][data-aos-delay='2250'], body[data-aos-delay='2250'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2250'].aos-animate, body[data-aos-delay='2250'] [data-aos].aos-animate { transition-delay: 2.25s; }

[data-aos][data-aos][data-aos-duration='2300'], body[data-aos-duration='2300'] [data-aos] { transition-duration: 2.3s; }

[data-aos][data-aos][data-aos-delay='2300'], body[data-aos-delay='2300'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2300'].aos-animate, body[data-aos-delay='2300'] [data-aos].aos-animate { transition-delay: 2.3s; }

[data-aos][data-aos][data-aos-duration='2350'], body[data-aos-duration='2350'] [data-aos] { transition-duration: 2.35s; }

[data-aos][data-aos][data-aos-delay='2350'], body[data-aos-delay='2350'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2350'].aos-animate, body[data-aos-delay='2350'] [data-aos].aos-animate { transition-delay: 2.35s; }

[data-aos][data-aos][data-aos-duration='2400'], body[data-aos-duration='2400'] [data-aos] { transition-duration: 2.4s; }

[data-aos][data-aos][data-aos-delay='2400'], body[data-aos-delay='2400'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2400'].aos-animate, body[data-aos-delay='2400'] [data-aos].aos-animate { transition-delay: 2.4s; }

[data-aos][data-aos][data-aos-duration='2450'], body[data-aos-duration='2450'] [data-aos] { transition-duration: 2.45s; }

[data-aos][data-aos][data-aos-delay='2450'], body[data-aos-delay='2450'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2450'].aos-animate, body[data-aos-delay='2450'] [data-aos].aos-animate { transition-delay: 2.45s; }

[data-aos][data-aos][data-aos-duration='2500'], body[data-aos-duration='2500'] [data-aos] { transition-duration: 2.5s; }

[data-aos][data-aos][data-aos-delay='2500'], body[data-aos-delay='2500'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2500'].aos-animate, body[data-aos-delay='2500'] [data-aos].aos-animate { transition-delay: 2.5s; }

[data-aos][data-aos][data-aos-duration='2550'], body[data-aos-duration='2550'] [data-aos] { transition-duration: 2.55s; }

[data-aos][data-aos][data-aos-delay='2550'], body[data-aos-delay='2550'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2550'].aos-animate, body[data-aos-delay='2550'] [data-aos].aos-animate { transition-delay: 2.55s; }

[data-aos][data-aos][data-aos-duration='2600'], body[data-aos-duration='2600'] [data-aos] { transition-duration: 2.6s; }

[data-aos][data-aos][data-aos-delay='2600'], body[data-aos-delay='2600'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2600'].aos-animate, body[data-aos-delay='2600'] [data-aos].aos-animate { transition-delay: 2.6s; }

[data-aos][data-aos][data-aos-duration='2650'], body[data-aos-duration='2650'] [data-aos] { transition-duration: 2.65s; }

[data-aos][data-aos][data-aos-delay='2650'], body[data-aos-delay='2650'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2650'].aos-animate, body[data-aos-delay='2650'] [data-aos].aos-animate { transition-delay: 2.65s; }

[data-aos][data-aos][data-aos-duration='2700'], body[data-aos-duration='2700'] [data-aos] { transition-duration: 2.7s; }

[data-aos][data-aos][data-aos-delay='2700'], body[data-aos-delay='2700'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2700'].aos-animate, body[data-aos-delay='2700'] [data-aos].aos-animate { transition-delay: 2.7s; }

[data-aos][data-aos][data-aos-duration='2750'], body[data-aos-duration='2750'] [data-aos] { transition-duration: 2.75s; }

[data-aos][data-aos][data-aos-delay='2750'], body[data-aos-delay='2750'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2750'].aos-animate, body[data-aos-delay='2750'] [data-aos].aos-animate { transition-delay: 2.75s; }

[data-aos][data-aos][data-aos-duration='2800'], body[data-aos-duration='2800'] [data-aos] { transition-duration: 2.8s; }

[data-aos][data-aos][data-aos-delay='2800'], body[data-aos-delay='2800'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2800'].aos-animate, body[data-aos-delay='2800'] [data-aos].aos-animate { transition-delay: 2.8s; }

[data-aos][data-aos][data-aos-duration='2850'], body[data-aos-duration='2850'] [data-aos] { transition-duration: 2.85s; }

[data-aos][data-aos][data-aos-delay='2850'], body[data-aos-delay='2850'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2850'].aos-animate, body[data-aos-delay='2850'] [data-aos].aos-animate { transition-delay: 2.85s; }

[data-aos][data-aos][data-aos-duration='2900'], body[data-aos-duration='2900'] [data-aos] { transition-duration: 2.9s; }

[data-aos][data-aos][data-aos-delay='2900'], body[data-aos-delay='2900'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2900'].aos-animate, body[data-aos-delay='2900'] [data-aos].aos-animate { transition-delay: 2.9s; }

[data-aos][data-aos][data-aos-duration='2950'], body[data-aos-duration='2950'] [data-aos] { transition-duration: 2.95s; }

[data-aos][data-aos][data-aos-delay='2950'], body[data-aos-delay='2950'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2950'].aos-animate, body[data-aos-delay='2950'] [data-aos].aos-animate { transition-delay: 2.95s; }

[data-aos][data-aos][data-aos-duration='3000'], body[data-aos-duration='3000'] [data-aos] { transition-duration: 3s; }

[data-aos][data-aos][data-aos-delay='3000'], body[data-aos-delay='3000'] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='3000'].aos-animate, body[data-aos-delay='3000'] [data-aos].aos-animate { transition-delay: 3s; }

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] { transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] { transition-timing-function: ease; }

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] { transition-timing-function: ease-in; }

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] { transition-timing-function: ease-out; }

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] { transition-timing-function: ease-in-out; }

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] { transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] { transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] { transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] { transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] { transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] { transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] { transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] { transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] { transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] { transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] { transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] { transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] { transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] { transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] { transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos^=fade][data-aos^=fade] { opacity: 0; transition-property: opacity,transform; }

[data-aos^=fade][data-aos^=fade].aos-animate { opacity: 1; transform: translate(0); }

[data-aos=fade-up] { transform: translateY(100px); }

[data-aos=fade-down] { transform: translateY(-100px); }

[data-aos=fade-right] { transform: translate(-100px); }

[data-aos=fade-left] { transform: translate(100px); }

[data-aos=fade-up-right] { transform: translate(-100px, 100px); }

[data-aos=fade-up-left] { transform: translate(100px, 100px); }

[data-aos=fade-down-right] { transform: translate(-100px, -100px); }

[data-aos=fade-down-left] { transform: translate(100px, -100px); }

[data-aos^=zoom][data-aos^=zoom] { opacity: 0; transition-property: opacity,transform; }

[data-aos^=zoom][data-aos^=zoom].aos-animate { opacity: 1; transform: translate(0) scale(1); }

[data-aos=zoom-in] { transform: scale(0.6); }

[data-aos=zoom-in-up] { transform: translateY(100px) scale(0.6); }

[data-aos=zoom-in-down] { transform: translateY(-100px) scale(0.6); }

[data-aos=zoom-in-right] { transform: translate(-100px) scale(0.6); }

[data-aos=zoom-in-left] { transform: translate(100px) scale(0.6); }

[data-aos=zoom-out] { transform: scale(1.2); }

[data-aos=zoom-out-up] { transform: translateY(100px) scale(1.2); }

[data-aos=zoom-out-down] { transform: translateY(-100px) scale(1.2); }

[data-aos=zoom-out-right] { transform: translate(-100px) scale(1.2); }

[data-aos=zoom-out-left] { transform: translate(100px) scale(1.2); }

[data-aos^=slide][data-aos^=slide] { transition-property: transform; }

[data-aos^=slide][data-aos^=slide].aos-animate { transform: translate(0); }

[data-aos=slide-up] { transform: translateY(100%); }

[data-aos=slide-down] { transform: translateY(-100%); }

[data-aos=slide-right] { transform: translateX(-100%); }

[data-aos=slide-left] { transform: translateX(100%); }

[data-aos^=flip][data-aos^=flip] { backface-visibility: hidden; transition-property: transform; }

[data-aos=flip-left] { transform: perspective(2500px) rotateY(-100deg); }

[data-aos=flip-left].aos-animate { transform: perspective(2500px) rotateY(0); }

[data-aos=flip-right] { transform: perspective(2500px) rotateY(100deg); }

[data-aos=flip-right].aos-animate { transform: perspective(2500px) rotateY(0); }

[data-aos=flip-up] { transform: perspective(2500px) rotateX(-100deg); }

[data-aos=flip-up].aos-animate { transform: perspective(2500px) rotateX(0); }

[data-aos=flip-down] { transform: perspective(2500px) rotateX(100deg); }

[data-aos=flip-down].aos-animate { transform: perspective(2500px) rotateX(0); }

/*# sourceMappingURL=aos.css.map*/
/* --- CSS Mixins ---*/
header .w-header-b, header nav, header .w-contact-details-b .w-time-b, .w-intro-b .w-advantages-b .w-advantages-list-b, .w-working-scheme-b .w-scheme-list-b, footer .w-vk-widget-b, footer .w-footer-line, footer .w-footer-line .w-menu, .w-share-b { *zoom: 1; }

header .w-header-b:before, header nav:before, header .w-contact-details-b .w-time-b:before, .w-intro-b .w-advantages-b .w-advantages-list-b:before, .w-working-scheme-b .w-scheme-list-b:before, footer .w-vk-widget-b:before, footer .w-footer-line:before, footer .w-footer-line .w-menu:before, .w-share-b:before, header .w-header-b:after, header nav:after, header .w-contact-details-b .w-time-b:after, .w-intro-b .w-advantages-b .w-advantages-list-b:after, .w-working-scheme-b .w-scheme-list-b:after, footer .w-vk-widget-b:after, footer .w-footer-line:after, footer .w-footer-line .w-menu:after, .w-share-b:after { content: " "; display: table; }

header .w-header-b:after, header nav:after, header .w-contact-details-b .w-time-b:after, .w-intro-b .w-advantages-b .w-advantages-list-b:after, .w-working-scheme-b .w-scheme-list-b:after, footer .w-vk-widget-b:after, footer .w-footer-line:after, footer .w-footer-line .w-menu:after, .w-share-b:after { clear: both; }

/* --- Media Queries ---*/
/* --- Unit Transform --- */
/*   Used for making containers have width in percentages.  Usage: define elemnt width in px and the width of parent elemnt in px.  eg. .block{width:cp(512px, 1024px)} this will result in .block{width:50%;}    */
/*   Used for making px values convert to rem values Usage: define font-size in px and it will convert to rems eg. font-size: rem(14px); */
/* Fonts */
/* --- Common styles --- */
html { font-size: 100%; height: 100%; }

body { color: #2e3239; font-family: 'Exo 2', sans-serif; font-size: 16px; font-weight: 400; line-height: 1.35; background: #fff; min-width: 320px; }

* { box-sizing: border-box; }

a { color: #3a90d9; outline: 0 none; text-decoration: underline; }

a:hover { color: #4674cc; }

a:focus { text-decoration: none; outline: 0 none; }

.g-dashed-link { text-decoration: none; border-bottom: 1px dashed; }

h1, h2, h3, h4 { text-transform: uppercase; line-height: 1.2; }

b { font-weight: 600; }

img { width: auto; max-width: 100%; }

input[type="text"], input[type="email"], textarea { width: 100%; color: #586378; font-family: 'Exo 2', sans-serif; font-size: 1em; font-weight: 400; background: #fff; padding: 9.5px 15px; border: none; border: 1px solid #fff; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; border-radius: 5px; -moz-box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1); -o-box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1); -webkit-box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1); box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1); outline: 0 none; }

input, button, input[type="text"], input[type="email"], textarea { outline: 0 none; }

::-webkit-input-placeholder { color: #adb3c1; }

::-moz-placeholder { color: #adb3c1; }

:-ms-input-placeholder { color: #adb3c1; }

/* --- Layout --- */
.l-wrapper { width: 1170px; margin: 0 auto; }

@media (max-width: 1200px) { .l-wrapper { width: 960px; } }

@media (max-width: 990px) { .l-wrapper { width: 720px; padding: 0 20px; } }

@media (max-width: 740px) { .l-wrapper { width: 580px; padding: 0 10px; } }

@media (max-width: 600px) { .l-wrapper { width: 100%; } }

.l-overlay { display: none; width: 100%; height: 100%; position: fixed; background: url("../images/page-overlay-bg.png") repeat; background: rgba(0, 0, 0, 0.9); top: 0; left: 0; z-index: 98; }

.l-preloader { background: #586378; position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 99; }

.l-preloader .g-preloader { display: block; width: 100px; height: 100px; top: 50%; left: 50%; margin: -50px 0 0 -50px; position: absolute; z-index: 100; }

/* --- Main styles --- */
.g-anchor-pos { display: block; content: ""; height: 0; }

.g-hidden-b { display: none; }

/* --- Buttons --- */
.btn-wrapper { display: block; }

.button, .btn { color: #fff; display: inline-block; cursor: pointer; font-family: 'Exo 2', sans-serif; font-size: 1em; font-weight: 700; line-height: 100%; text-transform: uppercase; text-decoration: none; text-align: center; padding: 13px 30px; border: none; background: #556074; border-bottom: 3px solid #3a4250; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; border-radius: 5px; }

.button:hover, .btn:hover { color: #fff; background: #657084; }

.button:active, .btn:active { margin-top: 3px; border-bottom: none; -moz-box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.15); -o-box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.15); -webkit-box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.15); box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.15); }

.button.btn-green, .btn.btn-green { background: #3fa766; border-bottom-color: #0b6f31; }

.button.btn-green:hover, .btn.btn-green:hover { background: #53b076; }

/* --- Multiselect --- */
.ms-options-wrap, .ms-options-wrap * { box-sizing: border-box; }

.ms-options-wrap > button:focus, .ms-options-wrap > button { width: 100%; color: #adb3c1; font-family: 'Exo 2', sans-serif; font-size: 1em; font-weight: 400; background: #fff; padding: 9.5px 15px; border: none; border: 1px solid #fff; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; border-radius: 5px; -moz-box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1); -o-box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1); -webkit-box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1); box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1); outline: 0 none; position: relative; text-align: left; white-space: nowrap; }

.ms-options-wrap.g-filled > button { color: #586378; }

.ms-options-wrap > button[disabled] { background-color: #e5e9ed; color: #808080; opacity: 0.6; }

.ms-options-wrap > button:after { content: ' '; height: 0; position: absolute; top: 50%; right: 15px; width: 0; border: 6px solid transparent; border-top-color: #586378; margin-top: -3px; }

.ms-options-wrap.g-opened > button:after { border-top-color: transparent; border-bottom-color: #586378; margin-top: -9px; }

.ms-options-wrap > .ms-options { position: absolute; left: 0; width: 100%; margin-top: 1px; padding: 12px; background: #fff; z-index: 99; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; border-radius: 5px; -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15) ; -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15) ; -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15) ; box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15) ; }

.ms-options-wrap > .ms-options > .ms-search input { width: 100%; padding: 4px 5px; border: none; border-bottom: 1px groove; outline: none; }

.ms-options-wrap > .ms-options .ms-selectall { display: inline-block; font-size: .9em; text-transform: lowercase; text-decoration: none; }

.ms-options-wrap > .ms-options .ms-selectall:hover { text-decoration: underline; }

.ms-options-wrap > .ms-options > .ms-selectall.global { margin: 4px 5px; }

.ms-options-wrap > .ms-options > ul, .ms-options-wrap > .ms-options > ul > li.optgroup ul { list-style-type: none; padding: 0; margin: 0; }

.ms-options-wrap > .ms-options > ul > li.optgroup { padding: 5px; }

.ms-options-wrap > .ms-options > ul > li.optgroup + li.optgroup { border-top: 1px solid #aaa; }

.ms-options-wrap > .ms-options > ul > li.optgroup .label { display: block; padding: 5px 0 0 0; font-weight: bold; }

.ms-options-wrap > .ms-options > ul label { position: relative; display: inline-block; color: #586378; font-size: 0.875em; font-weight: 600; line-height: 1.25; width: 100%; padding: 4px; margin: 1px 0; -webkit-border-radius: 3px; -moz-border-radius: 3px; -ms-border-radius: 3px; border-radius: 3px; -moz-transition: background 0.25s; -o-transition: background 0.25s; -webkit-transition: background 0.25s; transition: background 0.25s; }

.ms-options-wrap > .ms-options > ul label.focused, .ms-options-wrap > .ms-options > ul label:hover { background-color: #efefef; }

.ms-options-wrap > .ms-options > ul li.selected label { color: #fff; background-color: #586378; }

.ms-options-wrap > .ms-options > ul input[type="checkbox"] { margin: 0 5px 0 0; position: absolute; left: 4px; top: 6px; }

.ms-options-wrap > .ms-options.hide-checkbox > ul input[type="checkbox"] { position: absolute !important; height: 1px; width: 1px; overflow: hidden; clip: rect(1px 1px 1px 1px); clip: rect(1px, 1px, 1px, 1px); }

/* --- Slick slider --- */
.slick-slider { position: relative; display: block; box-sizing: border-box; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -webkit-touch-callout: none; -khtml-user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-slider * { outline: 0 none; }

.slick-list { position: relative; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; top: 0; left: 0; display: block; }

.slick-track:before, .slick-track:after { display: table; content: ''; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { display: none; float: left; height: 100%; min-height: 1px; }

[dir='rtl'] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow.slick-hidden { display: none; }

/* Parts */
/* Header */
section { display: block; padding: 100px 0; }

section h2, section h3 { color: #fff; display: block; text-align: center; margin-bottom: 40px; }

section h2 { font-size: 2em; font-weight: 600; }

section h3 { font-size: 1.55em; font-weight: 700; }

@media (max-width: 990px) { section { padding: 80px 0; }
  section h2 { font-size: 1.55em; }
  section h3 { font-size: 1.25em; } }

@media (max-width: 600px) { section { padding: 50px 0; }
  section h2, section h3 { margin-bottom: 25px; }
  section h2 { font-size: 1.15em; }
  section h3 { font-size: 1em; } }

header { display: block; width: 100%; height: 95px; position: fixed; top: 0; left: 0; z-index: 5; background: url("../images/header-bg.png") repeat; background: rgba(255, 255, 255, 0.95); }

header:after { display: block; content: ""; position: absolute; top: 100%; left: 0; height: 10px; width: 100%; background: url("../images/header-shadow.png") repeat-x; }

header .w-header-b { position: relative; }

header .g-logo { display: block; float: left; width: 255px; height: 42px; margin-top: 25px; background: url("../images/logo-remont12.png") 0 0 no-repeat; -moz-transition: opacity 0.25s ease-in-out; -o-transition: opacity 0.25s ease-in-out; -webkit-transition: opacity 0.25s ease-in-out; transition: opacity 0.25s ease-in-out; }

header .g-logo:hover { opacity: 0.8; filter: alpha(opacity=80); }

header nav { display: block; position: absolute; top: 33px; left: 300px; right: 300px; }

header nav ul { display: table; width: 100%; }

header nav ul li { display: table-cell; text-align: center; padding: 0 10px; }

header nav ul li a { display: inline-block; color: #3a90d9; font-weight: 700; text-transform: uppercase; text-decoration: none; padding: 2px; background: transparent; position: relative; }

header nav ul li a:after { content: ''; display: block; margin: 0 auto; height: 2px; width: 0px; background: transparent; -moz-transition: all 0.25s; -o-transition: all 0.25s; -webkit-transition: all 0.25s; transition: all 0.25s; }

header nav ul li a:hover { text-decoration: none; }

header nav ul li a:hover:after { width: 100%; background: #3a90d9; }

header nav ul li.g-act a { color: #556074; }

header nav ul li.g-act a:after { width: 100%; background: #556074; }

header .w-contact-details-b { float: right; margin-top: 10px; }

header .w-contact-details-b .w-phones-b { position: relative; padding-left: 54px; }

header .w-contact-details-b .w-phones-b .g-icn-phone { display: block; position: absolute; top: 5px; left: 0; width: 44px; height: 44px; float: left; }

header .w-contact-details-b .w-phones-b .g-icn-phone img { width: 100%; }

header .w-contact-details-b .w-phones-b p, header .w-contact-details-b .w-phones-b strong { display: block; white-space: nowrap; color: #556074; font-size: 1.5em; font-weight: 700; line-height: 1; }

header .w-contact-details-b .w-phones-b strong { margin-top: 2px; color: #3fa766; }

header .w-contact-details-b .w-phones-b strong a { color: #3fa766; text-decoration: none; }

header .w-contact-details-b .w-phones-b strong a:hover { color: #3fa766; }

header .w-contact-details-b .w-time-b { margin: 5px 0 0 54px; font-weight: 600; line-height: 1; }

header .w-contact-details-b .w-time-b .g-icn-time { float: left; width: 13px; height: 13px; margin: 1px 0 0 0; background: url("../images/icn-workingtime.png") 0 0 no-repeat; }

header .w-contact-details-b .w-time-b p { float: left; margin: 1px 0 0 5px; color: #556074; font-size: 0.875em; }

header .w-contact-details-b .w-time-b span { color: #fff; float: right; font-size: 0.75em; padding: 1px 5px 3px 5px; text-align: center; background: #3fa766; }

header .btn-menu { display: none; cursor: pointer; width: 40px; height: 40px; float: right; margin-top: 5px; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); -moz-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; }

header .btn-menu:before { color: #3a90d9; content: "Меню"; display: block; position: absolute; right: 100%; top: 50%; margin: -13px 10px 0 0; font-size: 0.875em; font-weight: 600; text-transform: uppercase; }

header .btn-menu span { display: block; position: absolute; height: 6px; width: 100%; background: #3a90d9; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; border-radius: 5px; left: 0; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); opacity: 1; filter: alpha(opacity=100); -moz-transition: all 0.25s ease-in-out; -o-transition: all 0.25s ease-in-out; -webkit-transition: all 0.25s ease-in-out; transition: all 0.25s ease-in-out; }

header .btn-menu span:nth-child(1) { top: 0px; }

header .btn-menu span:nth-child(2) { top: 12px; }

header .btn-menu span:nth-child(3) { top: 24px; }

header .btn-menu.g-active span:nth-child(1) { top: 12px; -webkit-transform: rotate(135deg); -moz-transform: rotate(135deg); -o-transform: rotate(135deg); transform: rotate(135deg); }

header .btn-menu.g-active span:nth-child(2) { left: -120px; opacity: 0; filter: alpha(opacity=0); }

header .btn-menu.g-active span:nth-child(3) { top: 12px; -webkit-transform: rotate(-135deg); -moz-transform: rotate(-135deg); -o-transform: rotate(-135deg); transform: rotate(-135deg); }

header .btn-menu:hover span { background: #4674cc; }

@media (max-width: 1200px) { header { height: 85px; }
  header .g-logo { width: 170px; height: 25px; background-image: url("../images/logo-remont12-l.png"); margin-top: 28px; }
  header nav { left: 200px; right: 200px; top: 30px; }
  header nav li a { font-size: 0.875em; font-weight: 900; }
  header .w-contact-details-b .w-phones-b { padding-left: 0; }
  header .w-contact-details-b .w-phones-b .g-icn-phone { display: none; }
  header .w-contact-details-b .w-phones-b p, header .w-contact-details-b .w-phones-b strong { font-size: 1.25em; }
  header .w-contact-details-b .w-time-b { margin-left: 0; }
  header .w-contact-details-b .w-time-b p { font-size: 0.7em; margin-left: 4px; }
  header .w-contact-details-b .w-time-b span { padding: 1px 3px 3px 3px; font-size: 0.65em; } }

@media (max-width: 990px) { header { height: auto; }
  header .w-header-b { padding: 10px 0 5px 0; position: static; -moz-transition: all 0.55s ease-in-out; -o-transition: all 0.55s ease-in-out; -webkit-transition: all 0.55s ease-in-out; transition: all 0.55s ease-in-out; }
  header .w-header-b .g-logo { margin-top: 8px; }
  header .w-header-b .btn-menu { display: block; }
  header .w-header-b nav { top: auto; bottom: 15px; width: 100%; left: 0; right: 0; padding-top: 15px; opacity: 0; filter: alpha(opacity=0); visibility: hidden; -moz-transition: all 0.45s ease-in-out; -o-transition: all 0.45s ease-in-out; -webkit-transition: all 0.45s ease-in-out; transition: all 0.45s ease-in-out; background: url("../images/header-shadow.png") 0 0 repeat-x; }
  header .w-header-b .w-contact-details-b { position: static; float: right; margin: 0 80px 0 0; width: 170px; text-align: center; }
  header .w-header-b .w-contact-details-b .w-phones-b .g-icn-phone { display: block; width: 30px; height: 30px; left: -40px; }
  header .w-header-b .w-contact-details-b .w-phones-b p { display: none; }
  header .w-header-b .w-contact-details-b .w-phones-b strong { margin-top: 0; }
  header .w-header-b .w-contact-details-b .w-time-b { margin-top: 2px; }
  header .w-header-b.g-opened { padding-bottom: 60px; }
  header .w-header-b.g-opened nav { visibility: visible; border-top-color: #efefef; opacity: 1; filter: alpha(opacity=100); } }

@media (max-width: 600px) { header .w-header-b .w-contact-details-b { display: none; }
  header .w-header-b.g-opened { padding-bottom: 185px; }
  header .w-header-b nav li { display: block; text-align: center; margin: 4px 0; } }

.w-intro-b { background: url("../images/intro-block-repeat-bg.jpg") 0 0 repeat-x; overflow: hidden; zoom: 1; }

.w-intro-b .w-bg-b { background: url("../images/intro-block-bg.jpg") 50% 0 no-repeat; }

.w-intro-b .l-wrapper { padding-top: 210px; position: relative; }

@media (max-width: 600px) { .w-intro-b .l-wrapper { padding-top: 170px; } }

.w-intro-b .w-contact-request-form-b { width: 460px; color: #fff; margin: 0 auto; }

@media (max-width: 600px) { .w-intro-b .w-contact-request-form-b { width: 95%; } }

.w-intro-b .w-advantages-b { padding: 290px 0 70px 0; }

.w-intro-b .w-advantages-b h2 { color: #fff; font-size: 2em; font-weight: 600; display: block; text-align: center; margin-bottom: 40px; }

.w-intro-b .w-advantages-b .w-advantages-list-b { padding: 20px 0; display: table; width: 100%; }

.w-intro-b .w-advantages-b .w-advantages-list-b li { display: table-cell; width: 25%; position: relative; vertical-align: middle; }

.w-intro-b .w-advantages-b .w-advantages-list-b li .g-icn { display: block; width: 140px; height: 140px; margin: 0 auto; -moz-transition: all 0.35s ease-in-out; -o-transition: all 0.35s ease-in-out; -webkit-transition: all 0.35s ease-in-out; transition: all 0.35s ease-in-out; }

.w-intro-b .w-advantages-b .w-advantages-list-b li .g-icn img { width: 100%; height: auto; }

.w-intro-b .w-advantages-b .w-advantages-list-b li .w-cont { vertical-align: middle; margin: 20px 70px 0 70px; text-align: center; }

.w-intro-b .w-advantages-b .w-advantages-list-b li .w-cont p { color: #fff; font-size: 1.25em; line-height: 1.5; font-weight: 600; text-transform: uppercase; }

.w-intro-b .w-advantages-b .w-advantages-list-b li:hover .g-icn { transform: scale(0.9); }

@media (max-width: 1200px) { .w-intro-b .w-advantages-b .w-advantages-list-b li .w-cont { margin: 20px 40px 0 40px; } }

@media (max-width: 990px) { .w-intro-b .w-advantages-b h2 { font-size: 1.65em; }
  .w-intro-b .w-advantages-b .w-advantages-list-b li .g-icn { width: 100px; height: 100px; }
  .w-intro-b .w-advantages-b .w-advantages-list-b li .w-cont { margin: 20px 30px 0 30px; }
  .w-intro-b .w-advantages-b .w-advantages-list-b li .w-cont p { font-size: 1em; } }

@media (max-width: 740px) { .w-intro-b .w-advantages-b { padding: 250px 0 50px 0; }
  .w-intro-b .w-advantages-b h2 { margin-bottom: 0; }
  .w-intro-b .w-advantages-b .w-advantages-list-b { padding: 0; display: block; }
  .w-intro-b .w-advantages-b .w-advantages-list-b li { margin-top: 40px; display: block; width: 49.15254%; float: left; }
  .w-intro-b .w-advantages-b .w-advantages-list-b li:nth-child(2n + 1) { margin-left: 0; margin-right: -100%; clear: both; margin-left: 0; }
  .w-intro-b .w-advantages-b .w-advantages-list-b li:nth-child(2n + 2) { margin-left: 50.84746%; margin-right: -100%; clear: none; }
  .w-intro-b .w-advantages-b .w-advantages-list-b li .g-icn { width: 110px; height: 110px; }
  .w-intro-b .w-advantages-b .w-advantages-list-b li .w-cont { margin: 10px 0 0; }
  .w-intro-b .w-advantages-b .w-advantages-list-b li .w-cont p { font-size: 0.9375em; } }

@media (max-width: 600px) { .w-intro-b .w-advantages-b h2 { font-size: 1.25em; margin-bottom: 10px; }
  .w-intro-b .w-advantages-b .w-advantages-list-b { display: block; }
  .w-intro-b .w-advantages-b .w-advantages-list-b li { margin-bottom: 20px; }
  .w-intro-b .w-advantages-b .w-advantages-list-b li .g-icn { width: 90px; height: 90px; }
  .w-intro-b .w-advantages-b .w-advantages-list-b li .w-cont { margin: 10px 10px 0 10px; }
  .w-intro-b .w-advantages-b .w-advantages-list-b li .w-cont p { font-size: 0.9375em; } }

.w-contact-form-b .w-inp-b { margin: 30px 0; position: relative; }

.w-contact-form-b .w-inp-b select { height: 40px; visibility: hidden; }

.w-contact-form-b .w-inp-b .help-block { color: #fff; font-size: 0.725em; position: absolute; top: -20px; right: 0; padding: 1px 5px 3px 5px; -webkit-border-radius: 2px; -moz-border-radius: 2px; -ms-border-radius: 2px; border-radius: 2px; }

.w-contact-form-b .w-inp-b .help-block.form-error { background-color: #ff5050; }

.w-contact-form-b .w-inp-b .help-block.form-error:after { content: ' '; height: 0; position: absolute; top: 100%; right: 15px; width: 0; border: 10px solid transparent; border-top-color: #ff5050; margin-top: -5px; }

.w-contact-form-b .w-inp-b.has-error input[type="text"], .w-contact-form-b .w-inp-b.has-error .ms-options-wrap > button { border-color: #ff5050; background: #fff; }

.w-contact-form-b .w-action-b { margin-top: 30px; text-align: center; }

.w-contact-request-form-b .w-contact-form-b { margin: 30px 60px 0 60px; }

.w-contact-request-form-b h1 { font-size: 1.675em; line-height: 1.5; font-weight: 800; padding: 0 20px; text-align: center; }

.w-contact-request-form-b p { margin-top: 20px; font-weight: 600; text-align: center; }

@media (max-width: 600px) { .w-contact-request-form-b { width: 100%; }
  .w-contact-request-form-b h1 { font-size: 1.25em; }
  .w-contact-request-form-b p { font-size: 0.875em; }
  .w-contact-request-form-b .w-contact-form-b { margin: 30px auto; max-width: 360px; } }

.w-intro-descr-b { text-align: center; }

.w-intro-descr-b h3 { color: #3fa766; }

.w-intro-descr-b p { font-size: 1.25em; margin-top: 20px; }

@media (max-width: 600px) { .w-intro-descr-b p { font-size: 1em; margin-top: 10px; } }

.w-working-scheme-b { background: #3fa766 url("../images/howitworks-block-bg.jpg") 50% 100% no-repeat; overflow: hidden; zoom: 1; }

.w-working-scheme-b .w-scheme-list-b { display: block; padding: 20px 0; }

.w-working-scheme-b .w-scheme-list-b li { width: 15.25424%; float: left; position: relative; }

.w-working-scheme-b .w-scheme-list-b li:nth-child(6n + 1) { margin-left: 0; margin-right: -100%; clear: both; margin-left: 0; }

.w-working-scheme-b .w-scheme-list-b li:nth-child(6n + 2) { margin-left: 16.94915%; margin-right: -100%; clear: none; }

.w-working-scheme-b .w-scheme-list-b li:nth-child(6n + 3) { margin-left: 33.89831%; margin-right: -100%; clear: none; }

.w-working-scheme-b .w-scheme-list-b li:nth-child(6n + 4) { margin-left: 50.84746%; margin-right: -100%; clear: none; }

.w-working-scheme-b .w-scheme-list-b li:nth-child(6n + 5) { margin-left: 67.79661%; margin-right: -100%; clear: none; }

.w-working-scheme-b .w-scheme-list-b li:nth-child(6n + 6) { margin-left: 84.74576%; margin-right: -100%; clear: none; }

.w-working-scheme-b .w-scheme-list-b li:after { display: block; content: ""; position: absolute; top: 55px; left: 100%; width: 17px; height: 28px; background: url("../images/icn-scheme-arr.png") 0 0 no-repeat; }

.w-working-scheme-b .w-scheme-list-b li .g-icn { display: block; width: 140px; height: 140px; margin: 0 auto; -moz-transition: all 0.35s ease-in-out; -o-transition: all 0.35s ease-in-out; -webkit-transition: all 0.35s ease-in-out; transition: all 0.35s ease-in-out; }

.w-working-scheme-b .w-scheme-list-b li .g-icn img { width: 100%; height: auto; }

.w-working-scheme-b .w-scheme-list-b li .w-cont { padding: 30px 10px; text-align: center; }

.w-working-scheme-b .w-scheme-list-b li .w-cont p { color: #fff; font-size: 1.2em; line-height: 1.5; font-weight: 600; text-transform: uppercase; }

.w-working-scheme-b .w-scheme-list-b li:hover .g-icn { transform: scale(0.9); }

.w-working-scheme-b .w-scheme-list-b li.g-last:after { display: none; }

@media (max-width: 1200px) { .w-working-scheme-b .w-scheme-list-b { padding-top: 40px; }
  .w-working-scheme-b .w-scheme-list-b li:after { top: 35px; }
  .w-working-scheme-b .w-scheme-list-b li .g-icn { width: 100px; height: 100px; }
  .w-working-scheme-b .w-scheme-list-b li .w-cont { padding: 20px 0; }
  .w-working-scheme-b .w-scheme-list-b li .w-cont p { font-size: 1em; } }

@media (max-width: 990px) { .w-working-scheme-b .w-scheme-list-b { padding-top: 0; }
  .w-working-scheme-b .w-scheme-list-b li { width: 32.20339%; float: left; margin-top: 40px; }
  .w-working-scheme-b .w-scheme-list-b li:nth-child(3n + 1) { margin-left: 0; margin-right: -100%; clear: both; margin-left: 0; }
  .w-working-scheme-b .w-scheme-list-b li:nth-child(3n + 2) { margin-left: 33.89831%; margin-right: -100%; clear: none; }
  .w-working-scheme-b .w-scheme-list-b li:nth-child(3n + 3) { margin-left: 67.79661%; margin-right: -100%; clear: none; }
  .w-working-scheme-b .w-scheme-list-b li:after { top: 35px; }
  .w-working-scheme-b .w-scheme-list-b li .w-cont { padding: 20px 40px; }
  .w-working-scheme-b .w-scheme-list-b li .w-cont p { font-size: 1em; }
  .w-working-scheme-b .w-scheme-list-b li.g-3:after { display: none; } }

@media (max-width: 740px) { .w-working-scheme-b .w-scheme-list-b { padding-top: 0; }
  .w-working-scheme-b .w-scheme-list-b li { width: 49.15254%; float: left; margin-top: 30px; }
  .w-working-scheme-b .w-scheme-list-b li:nth-child(2n + 1) { margin-left: 0; margin-right: -100%; clear: both; margin-left: 0; }
  .w-working-scheme-b .w-scheme-list-b li:nth-child(2n + 2) { margin-left: 50.84746%; margin-right: -100%; clear: none; }
  .w-working-scheme-b .w-scheme-list-b li:after { top: 35px; }
  .w-working-scheme-b .w-scheme-list-b li .g-icn { width: 110px; height: 110px; }
  .w-working-scheme-b .w-scheme-list-b li .w-cont { padding: 10px 40px; }
  .w-working-scheme-b .w-scheme-list-b li .w-cont p { font-size: 1em; }
  .w-working-scheme-b .w-scheme-list-b li.g-3:after { display: block; }
  .w-working-scheme-b .w-scheme-list-b li.g-2:after, .w-working-scheme-b .w-scheme-list-b li.g-4:after { display: none; } }

@media (max-width: 600px) { .w-working-scheme-b .w-scheme-list-b { padding-top: 0; }
  .w-working-scheme-b .w-scheme-list-b li { margin-top: 40px; }
  .w-working-scheme-b .w-scheme-list-b li:after { top: 25px; display: none; }
  .w-working-scheme-b .w-scheme-list-b li.g-1:after, .w-working-scheme-b .w-scheme-list-b li.g-3:after, .w-working-scheme-b .w-scheme-list-b li.g-5:after { display: block; }
  .w-working-scheme-b .w-scheme-list-b li .g-icn { width: 80px; height: 80px; }
  .w-working-scheme-b .w-scheme-list-b li .w-cont { padding: 10px 0; }
  .w-working-scheme-b .w-scheme-list-b li .w-cont br { display: none; } }

.w-prices-b { max-height: 890px; background: #4673ca url("../images/prices-block-bg.jpg") 50% 0 no-repeat; }

.w-prices-b .w-prices-tbl { margin: 0 100px; }

.w-prices-b .w-prices-tbl table { font-size: 1.375em; border-collapse: separate; border-spacing: 0 5px; width: 100%; }

.w-prices-b .w-prices-tbl table tr th { color: #fff; text-align: left; background-color: #3fa766; padding: 4px 30px; }

.w-prices-b .w-prices-tbl table tr td { text-align: left; background-color: #fff; padding: 4px 30px; -moz-transition: background 0.15s; -o-transition: background 0.15s; -webkit-transition: background 0.15s; transition: background 0.15s; }

.w-prices-b .w-prices-tbl table tr:nth-child(odd) td { background-color: #e8eeff; }

.w-prices-b .w-prices-tbl table tr:hover td { background-color: #f5f5f5; }

.w-prices-b .w-prices-tbl table tr:hover:nth-child(odd) td { background-color: #e1e7f7; }

.w-prices-b .w-note-b { color: #fff; margin: 30px 130px 0 130px; }

.w-prices-b .w-note-b p { color: #fff; font-size: 0.8175em; margin-top: 3px; }

@media (max-width: 990px) { .w-prices-b .w-prices-tbl { margin: 0; }
  .w-prices-b .w-note-b { margin: 30px 0 0; } }

@media (max-width: 740px) { .w-prices-b .w-prices-tbl table { font-size: 1em; }
  .w-prices-b .w-prices-tbl table tr th, .w-prices-b .w-prices-tbl table tr td { padding: 2px 15px; } }

@media (max-width: 600px) { .w-prices-b .w-prices-tbl table { font-size: 0.9375em; }
  .w-prices-b .w-prices-tbl table tr th, .w-prices-b .w-prices-tbl table tr td { padding: 2px 10px; }
  .w-prices-b .w-note-b p { font-size: 0.675em; } }

.w-feedbacks-b { background: #3fa766 url("../images/feedbacks-block-bg.jpg") 50% 100% no-repeat; }

.w-feedbacks-b h2 { color: #fff; margin-bottom: 0; }

.w-feedbacks-b h5 { color: #fff; text-align: center; }

.w-feedbacks-b .w-feedbacks-cont-b { position: relative; overflow: hidden; zoom: 1; }

.w-feedbacks-b .w-feedbacks-cont-b:before { display: block; content: ""; width: 50px; height: 100%; position: absolute; top: 0; left: 0; background: -moz-linear-gradient(left, #3fa866 0%, rgba(63, 168, 102, 0) 100%); /* FF3.6-15 */ background: -webkit-linear-gradient(left, #3fa866 0%, rgba(63, 168, 102, 0) 100%); /* Chrome10-25,Safari5.1-6 */ background: linear-gradient(to right, #3fa866 0%, rgba(63, 168, 102, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3fa866', endColorstr='#003fa866',GradientType=1 ); /* IE6-9 */ z-index: 10; }

.w-feedbacks-b .w-feedbacks-cont-b:after { display: block; content: ""; width: 50px; height: 100%; position: absolute; top: 0; right: 0; background: -moz-linear-gradient(left, rgba(63, 168, 102, 0) 0%, #3fa866 100%); /* FF3.6-15 */ background: -webkit-linear-gradient(left, rgba(63, 168, 102, 0) 0%, #3fa866 100%); /* Chrome10-25,Safari5.1-6 */ background: linear-gradient(to right, rgba(63, 168, 102, 0) 0%, #3fa866 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003fa866', endColorstr='#3fa866',GradientType=1 ); /* IE6-9 */ z-index: 10; }

.w-feedbacks-b .w-feedbacks-cont-b .strong-view .strong-content { margin: 70px 0 30px 0; }

.w-feedbacks-b .w-feedbacks-cont-b .strong-view .testimonial-inner { border: none; padding: 30px; position: relative; background: #fff; }

@media (max-width: 740px) { .w-feedbacks-b .w-feedbacks-cont-b .strong-view .testimonial-inner { padding: 30px 15px; } }

.w-feedbacks-b .w-feedbacks-cont-b .strong-view .testimonial { margin: 0; border: none; }

.w-feedbacks-b .w-feedbacks-cont-b .strong-view .testimonial .testimonial-content { border-top: 1px solid #e7e7e7; margin-top: 125px; padding-top: 25px; text-align: center; }

.w-feedbacks-b .w-feedbacks-cont-b .strong-view .testimonial .testimonial-client { width: 100%; position: absolute; top: 50px; left: 0; text-align: center; }

.w-feedbacks-b .w-feedbacks-cont-b .strong-view .testimonial .testimonial-client .testimonial-name { font-weight: 600; margin-bottom: 5px; }

.w-feedbacks-b .w-feedbacks-cont-b .strong-view .testimonial .testimonial-client .date { color: #8d8d8d; font-size: 0.9375em; }

.w-feedbacks-b .w-feedbacks-cont-b .strong-view .testimonial .testimonial-image { position: absolute; top: -45px; left: 50%; margin-left: -45px; border: none; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; border-radius: 50%; overflow: hidden; zoom: 1; }

.w-feedbacks-b .w-feedbacks-cont-b .strong-view .testimonial .testimonial-image img { background: #3fa766; border: none; padding: 5px; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; border-radius: 50%; overflow: hidden; zoom: 1; }

.w-feedbacks-b .w-feedbacks-cont-b .strong-view .g-slider .testimonial { float: left; margin: 0 30px; }

@media (max-width: 740px) { .w-feedbacks-b .w-feedbacks-cont-b .strong-view .g-slider .testimonial { margin: 0 10px; } }

.w-feedbacks-b .w-feedbacks-cont-b .slick-arrow { display: block; cursor: pointer; width: 38px; height: 38px; border: none; position: absolute; top: 50%; left: 32px; margin-top: -19px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; border-radius: 5px; opacity: 0.95; filter: alpha(opacity=95); text-indent: -99999em; -moz-transition: all 0.15s ease-in-out; -o-transition: all 0.15s ease-in-out; -webkit-transition: all 0.15s ease-in-out; transition: all 0.15s ease-in-out; background: #586378 url("../images/icns-slider-nav.png") -32px 0 no-repeat; border: 3px solid #586378; z-index: 99; }

.w-feedbacks-b .w-feedbacks-cont-b .slick-arrow:hover { opacity: 1; filter: alpha(opacity=100); }

.w-feedbacks-b .w-feedbacks-cont-b .slick-arrow.slick-next { left: auto; right: 32px; background-position: 0 0; }

.w-feedbacks-b .w-feedbacks-action-b { text-align: center; color: #fff; margin-top: 30px; }

.w-feedbacks-b .w-feedbacks-action-b .w-action-b { margin-top: 30px; }

.w-faq-b { padding-bottom: 0; }

.w-faq-b h2 { color: #2e3239; }

.w-faq-b .w-faq-list-b .w-item-b { position: relative; padding: 30px 30px 30px 45px; border-bottom: 1px solid #e7e7e7; }

.w-faq-b .w-faq-list-b .w-item-b .g-icn { display: block; width: 32px; height: 32px; position: absolute; top: 25px; left: 0; }

.w-faq-b .w-faq-list-b .w-item-b .g-icn img { width: 100%; margin: 0; }

.w-faq-b .w-faq-list-b .w-item-b h4 { display: block; color: #3c5c9c; font-size: 1.25em; font-weight: 600; }

.w-faq-b .w-faq-list-b .w-item-b p { margin-top: 15px; }

.w-faq-b .w-faq-list-b .w-item-b .w-pic-b { margin-top: 20px; }

.w-faq-b .w-faq-list-b .w-item-b.g-green h4 { color: #23944d; }

.w-faq-b .w-faq-list-b .w-item-b:last-of-type { border-bottom: none; padding-bottom: 0; }

@media (max-width: 740px) { .w-faq-b .w-faq-list-b .w-item-b { padding: 30px 0; }
  .w-faq-b .w-faq-list-b .w-item-b h4 { font-size: 1.15em; }
  .w-faq-b .w-faq-list-b .w-item-b .g-icn { display: none; }
  .w-faq-b .w-faq-list-b .w-item-b p { font-size: 0.9375em; margin-top: 10px; }
  .w-faq-b .w-faq-list-b .w-item-b .w-pic-b { display: none; } }

@media (max-width: 600px) { .w-faq-b .w-faq-list-b .w-item-b { padding: 30px 0; }
  .w-faq-b .w-faq-list-b .w-item-b h4 { font-size: 1em; } }

footer .w-vk-widget-b { background: #586378; padding: 50px 0; min-height: 400px; position: relative; }

footer .w-vk-widget-b:after { display: block; content: ""; position: absolute; bottom: -5px; left: 0; height: 10px; width: 100%; background: url("../images/header-shadow.png") repeat-x; }

footer .advert-link { color: #fff; margin-top: 30px; font-size: 12px; text-align: center; }

footer .advert-link a { color: #fff; }

footer .w-contact-info-b { color: #fff; text-align: center; padding: 50px 0; max-height: 270px; background: #3a71ce url("../images/footer-contact-block-bg.jpg") 50% 0 no-repeat; }

footer .w-contact-info-b a { color: #fff; text-decoration: none; }

footer .w-contact-info-b a:hover { color: #fff; }

footer .w-contact-info-b h3 { font-size: 1.5em; text-transform: none; }

footer .w-contact-info-b h3 b { display: block; font-size: 1.4em; margin-top: 5px; }

footer .w-contact-info-b p { margin: 10px 0 20px 0; }

footer .w-footer-line { background: #586378; padding: 50px 0 70px 0; position: relative; }

footer .w-footer-line:after { display: block; content: ""; position: absolute; top: 0; left: 0; height: 10px; width: 100%; background: url("../images/header-shadow.png") repeat-x; }

footer .w-footer-line .w-menu { float: left; }

footer .w-footer-line .w-menu li { float: left; text-align: center; padding-right: 30px; }

footer .w-footer-line .w-menu li a { display: inline-block; color: #fff; font-weight: 700; text-transform: uppercase; text-decoration: none; padding: 2px; background: transparent; position: relative; }

footer .w-footer-line .w-menu li a:after { content: ''; display: block; margin: 0 auto; height: 2px; width: 0px; background: transparent; -moz-transition: all 0.25s; -o-transition: all 0.25s; -webkit-transition: all 0.25s; transition: all 0.25s; }

footer .w-footer-line .w-menu li a:hover { text-decoration: none; }

footer .w-footer-line .w-menu li a:hover:after { width: 100%; background: #fff; }

footer .w-footer-line .w-copyright { float: right; padding-top: 2px; }

footer .w-footer-line .w-copyright p { color: #fff; }

@media (max-width: 1200px) { footer .w-footer-line .w-menu li a { font-size: 0.875em; }
  footer .w-footer-line .w-copyright { font-size: 0.875em; } }

@media (max-width: 990px) { footer .w-footer-line .w-menu, footer .w-footer-line .w-copyright { display: block; width: 100%; float: none; text-align: center; }
  footer .w-footer-line .w-menu { display: table; }
  footer .w-footer-line .w-menu li { width: 20%; padding: 0; display: table-cell; text-align: center; }
  footer .w-footer-line .w-menu li a { font-size: 0.875em; }
  footer .w-footer-line .w-copyright { margin-top: 20px; } }

@media (max-width: 600px) { footer .w-contact-info-b h3 { font-size: 1.25em; }
  footer .w-contact-info-b h3 b { font-size: 1.45em; }
  footer .w-footer-line .w-menu { display: block; }
  footer .w-footer-line .w-menu li { display: block; width: 100%; text-align: center; margin: 4px 0; } }

.w-share-b { padding: 60px 0; }

.w-share-b h3 { float: left; color: #556074; font-size: 1em; padding-right: 10px; text-transform: none; font-weight: normal; }

.w-share-b .w-yandex-share-b { float: left; }

.btn-scroll-top { position: fixed; bottom: 30px; right: 30px; padding: 10px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; border-radius: 5px; background: #586378; z-index: 10; opacity: 0; filter: alpha(opacity=0); -moz-transition: opacity 0.65s ease-in-out; -o-transition: opacity 0.65s ease-in-out; -webkit-transition: opacity 0.65s ease-in-out; transition: opacity 0.65s ease-in-out; visibility: hidden; }

.btn-scroll-top span { display: block; width: 32px; height: 32px; -moz-transition: opacity 0.15s; -o-transition: opacity 0.15s; -webkit-transition: opacity 0.15s; transition: opacity 0.15s; }

.btn-scroll-top:hover span { opacity: 0.8; filter: alpha(opacity=80); }

@media (max-width: 990px) { .btn-scroll-top { bottom: 20px; right: 20px; }
  .btn-scroll-top span { width: 22px; height: 22px; } }

.btn-scroll-top.g-shown { opacity: 1; filter: alpha(opacity=100); visibility: visible; }

@media (max-width: 740px) { .btn-scroll-top { display: none; }
  .btn-scroll-top.g-shown { opacity: 0; filter: alpha(opacity=0); visibility: hidden; } }

.btn-call-action { position: fixed; display: none; width: 42px; height: 42px; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; border-radius: 50%; z-index: 11; bottom: 20px; right: 20px; border: 2px solid #40ae67; box-shadow: 0 0.125em 0.5em #0b6f31, inset 0 0 0 3em #3fa766, 0 0 0 0 rgba(63, 167, 102, 0.25); background-color: #3fa766; animation: scaleUp 2s infinite, borderScale 2s infinite; }

.btn-call-action > svg { width: 30px; height: 30px; margin: 4px; fill: #fff; animation: shake 2s cubic-bezier(0.43, 0, 0.6, 0.4) infinite; }

@media (max-width: 740px) { .btn-call-action { display: block; } }

@keyframes borderScale { 0% { box-shadow: 0 0.125em 0.5em #0b6f31, inset 0 0 0 3em #3fa766, 0 0 0 0 rgba(63, 167, 102, 0.25); }
  50% { box-shadow: 0 0.125em 0.5em #0b6f31, inset 0 0 0 0.5em #3fa766, 0 0 0 0 rgba(63, 167, 102, 0.25); }
  100% { box-shadow: 0 0.125em 0.5em #0b6f31, inset 0 0 0 3em #3fa766, 0 0 0 3em rgba(63, 167, 102, 0); } }

@keyframes scaleUp { 0% { transform: scale(1); }
  50% { transform: scale(1.125); }
  60% { transform: scale(1); } }

@keyframes shake { 0% { transform: translate(0, 0); }
  1.25% { transform: translate(-2.5%, 0); }
  2.5% { transform: translate(0, 0); }
  3.75% { transform: translate(2.5%, 0); }
  5% { transform: translate(0, 0); }
  6.25% { transform: translate(-5%, 0); }
  7.5% { transform: translate(0, 0); }
  8.75% { transform: translate(5%, 0); }
  10% { transform: translate(0, 0); }
  11.25% { transform: translate(-5%, 0); }
  12.5% { transform: translate(0, 0); }
  13.75% { transform: translate(5%, 0); }
  15% { transform: translate(0, 0); }
  16.25% { transform: translate(-7.5%, 0); }
  17.5% { transform: translate(0, 0); }
  18.75% { transform: translate(7.5%, 0); }
  20% { transform: translate(0, 0); }
  21.25% { transform: translate(-7.5%, 0); }
  22.5% { transform: translate(0, 0); }
  23.75% { transform: translate(7.5%, 0); }
  25% { transform: translate(0, 0); }
  26.25% { transform: translate(-7.5%, 0); }
  27.5% { transform: translate(0, 0); }
  28.75% { transform: translate(7.5%, 0); }
  30% { transform: translate(0, 0); }
  31.25% { transform: translate(-7.5%, 0); }
  32.5% { transform: translate(0, 0); }
  33.75% { transform: translate(7.5%, 0); }
  35% { transform: translate(0, 0); }
  36.25% { transform: translate(-7.5%, 0); }
  37.5% { transform: translate(0, 0); }
  38.75% { transform: translate(7.5%, 0); }
  40% { transform: translate(0, 0); }
  41.25% { transform: translate(-7.5%, 0); }
  42.5% { transform: translate(0, 0); }
  43.75% { transform: translate(7.5%, 0); }
  45% { transform: translate(0, 0); }
  46.25% { transform: translate(-7.5%, 0); }
  47.5% { transform: translate(0, 0); }
  48.75% { transform: translate(7.5%, 0); }
  50% { transform: translate(0, 0); } }

.w-404-b { text-align: center; padding: 200px 0 170px 0; height: 100%; }

.w-404-b h2 { position: relative; margin: 30px auto; background: #586378; width: 200px; height: 200px; -webkit-border-radius: 100%; -moz-border-radius: 100%; -ms-border-radius: 100%; border-radius: 100%; }

.w-404-b h2 span { display: block; width: 180px; height: 115px; text-align: center; color: #fff; font-size: 3em; position: absolute; top: 50%; left: 50%; margin: -56px 0 0 -90px; }

.w-404-b .w-404-descr-b p { margin-top: 10px; }

.w-popup { color: #2e3239; display: none; position: fixed; top: 50%; left: 50%; z-index: 1000; opacity: 0; filter: alpha(opacity=0); -moz-transition: all 0.15s ease-in-out; -o-transition: all 0.15s ease-in-out; -webkit-transition: all 0.15s ease-in-out; transition: all 0.15s ease-in-out; }

.w-popup .w-content-b { background: #fff; position: relative; padding: 40px; -webkit-border-radius: 3px; -moz-border-radius: 3px; -ms-border-radius: 3px; border-radius: 3px; -moz-transition: all 0.25s; -o-transition: all 0.25s; -webkit-transition: all 0.25s; transition: all 0.25s; }

.w-popup .w-content-b .btn-close { display: block; width: 22px; height: 22px; position: absolute; top: -20px; right: -40px; background: url("../images/icn-close-popup.png") -22px 0 no-repeat; opacity: 0.5; filter: alpha(opacity=50); -moz-transition: opacity 0.25s; -o-transition: opacity 0.25s; -webkit-transition: opacity 0.25s; transition: opacity 0.25s; }

.w-popup .w-content-b .btn-close:hover { opacity: 1; filter: alpha(opacity=100); }

.w-popup .w-content-b input[type="text"], .w-popup .w-content-b input[type="email"], .w-popup .w-content-b textarea { background: #fff; padding: 9.5px 15px; border: 1px solid #a0acc1; background: #fefefe; -moz-box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1); -o-box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1); -webkit-box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1); box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1); }

.w-popup .w-content-b .ms-options-wrap > button:focus, .w-popup .w-content-b .ms-options-wrap > button { border: 1px solid #a0acc1; }

.w-popup .w-content-b h5 { display: block; text-align: center; margin-bottom: 20px; font-size: 2em; font-weight: 600; }

.w-popup .w-content-b p { font-weight: 600; }

.w-popup.g-success-popup .w-content-b { text-align: center; padding: 60px 120px; }

.w-popup.g-success-popup .w-content-b h5 { color: #3fa766; }

.w-popup.g-error-popup .w-content-b { text-align: center; padding: 60px 120px; }

.w-popup.g-error-popup .w-content-b h5 { color: #ff5050; }

.w-popup.g-contact-popup .w-content-b { width: 400px; }

.w-popup.g-contact-popup .w-contact-form-b .w-inp-b { margin: 0 0 30px 0; }

.w-popup.g-feedback-popup .w-content-b { width: 400px; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .strong-form-inner { padding: 0; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .testimonial-success { text-align: center; padding-top: 130px; height: 375px; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .testimonial-success h5 { color: #3fa766; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .form-field { position: relative; margin-bottom: 30px; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .form-field.submit { margin-bottom: 0; height: 45px; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .form-field label { color: #586378; font-size: 0.875em; font-weight: 600; line-height: 1.25; margin-bottom: 3px; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .form-field label.error { color: #fff; font-size: 0.725em; font-weight: 400; position: absolute; top: -19px; right: 0; padding: 1px 5px 3px 5px; -webkit-border-radius: 2px; -moz-border-radius: 2px; -ms-border-radius: 2px; border-radius: 2px; background-color: #ff5050; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .form-field label.error:after { content: ' '; height: 0; position: absolute; top: 100%; right: 15px; width: 0; border: 10px solid transparent; border-top-color: #ff5050; margin-top: -5px; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .form-field .required { display: none; font-size: 0.85em; font-weight: 600; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .form-field .required:after { position: absolute; top: 0; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .form-field input.error, .w-popup.g-feedback-popup .w-feedbacks-form-b .form-field textarea.error { border-color: #ff5050; background: #fff; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .form-field input[type="file"] { margin-top: 3px; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .form-field textarea { height: 125px; resize: none; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .form-field .strong-rating { margin-left: -3px; }

.w-popup.g-feedback-popup .w-feedbacks-form-b .form-field .strong-rating label { margin: 0 0 0 -1px; font-size: 1.15em; }

@media (max-width: 600px) { .w-popup { position: fixed; width: 100%; height: 100%; top: 0; left: 0; margin: 0 !important; }
  .w-popup .w-content-b { width: 100% !important; height: 100%; -webkit-border-radius: 0; -moz-border-radius: 0; -ms-border-radius: 0; border-radius: 0; padding-top: 70px; }
  .w-popup .w-content-b .btn-close { background-position: 0 0; top: 20px; right: 40px; } }

/* --- CSS Mixins ---*/

@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}
@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
	-moz-box-shadow: $top $left $blur $color #{$inset};
	-o-box-shadow: $top $left $blur $color #{$inset};	
	-webkit-box-shadow: $top $left $blur $color #{$inset};	
	box-shadow: $top $left $blur $color #{$inset};
}
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}
@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie);
}
@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}  
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}  
	@keyframes #{$animation-name} {
		@content;
	}
}
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/* --- Media Queries ---*/

@mixin breakpoint($break) {
	@if $break == large {
		@media (max-width: 1200px) { @content; }
	}
	@else if $break == medium {
		@media (max-width: 990px) { @content; }
	}
	@else if $break == small {
		@media (max-width: 740px) { @content; }
	}
	@else if $break == xsmall {
		@media (max-width: 600px) { @content; }
	} 
	@else {
		@media ($break) { @content; }
	}
}

/* --- Unit Transform --- */

/*  
    Used for making containers have width in percentages. 
    Usage: define elemnt width in px and the width of parent elemnt in px. 
    eg. .block{width:cp(512px, 1024px)} this will result in .block{width:50%;}   
*/

@function cp($target, $container) {
    @return ($target / $container) * 100%;
}

/*  
    Used for making px values convert to rem values
    Usage: define font-size in px and it will convert to rems
    eg. font-size: rem(14px);
*/

@function rem($target, $context: $base-font-size) {
   @if $target == 0 { @return 0 }
   @return $target / $context + 0rem;
}

$base-font-size: 16px;
/* Header */

section {
	display: block;
	padding: 100px 0;
	h2,
	h3 {
		color: #fff;
		display: block;
		text-align: center;
		margin-bottom: 40px;
	}
	h2 {		
		font-size: 2em;
		font-weight: 600;	
	}
	h3 {		
		font-size: 1.55em;		
		font-weight: 700;	
	}
	@include breakpoint(medium) {
		padding: 80px 0;
		h2 {
			font-size: 1.55em;
		}
		h3 {
			font-size: 1.25em;
		}
	}
	@include breakpoint(xsmall) {		
		padding: 50px 0;
		h2,
		h3 {
			margin-bottom: 25px;
		}
		h2 {
			font-size: 1.15em;
		}
		h3 {
			font-size: 1em;
		}
	}
}

header {
	display: block;
	width: 100%;
	height: 95px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
	background: url("../images/header-bg.png") repeat;
	background: rgba(255,255,255,0.95);
	&:after {
		display: block;
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		height: 10px;
		width: 100%;
		background: url("../images/header-shadow.png") repeat-x;
	}
	.w-header-b {
		@extend %clearfix;
		position: relative;
	}
	.g-logo {
		display: block;
		float: left;
		width: 255px;
		height: 42px;
		margin-top: 25px;		
		background: url("../images/logo-remont12.png") 0 0 no-repeat;
		@include transition(opacity 0.25s ease-in-out);
		&:hover {
			@include opacity(0.8);
		}
	}
	nav {
		display: block;		
		position: absolute;
		top: 33px;
		left: 300px;
		right: 300px;
		@extend %clearfix;
		ul {
			display: table;
			width: 100%;
			li {
				display: table-cell;
				text-align: center;
				padding: 0 10px;
				a {
					display: inline-block;
					color: #3a90d9;
					font-weight: 700;					
					text-transform: uppercase;
					text-decoration: none;
					padding: 2px;
					background: transparent;
					position: relative;
					&:after {
						content: '';
						display: block;
						margin: 0 auto;
						height: 2px;
						width: 0px;
						background: transparent;
						@include transition(all 0.25s);
					}
					&:hover {
						text-decoration: none;
						&:after {
							width: 100%;
							background: #3a90d9;
						}
					}
				}
				&.g-act a {
					color: #556074;
					&:after {
						width: 100%;
						background: #556074;
					}
				}
			}
		}
	}
	.w-contact-details-b {
		float: right;
		margin-top: 10px;
		.w-phones-b {
			position: relative;
			padding-left: 54px;
			.g-icn-phone {
				display: block;
				position: absolute;
				top: 5px;
				left: 0;
				width: 44px;
				height: 44px;
				float: left;				
				img {
					width: 100%;
				}
			}
			p,
			strong {
				display: block;
				white-space: nowrap;
				color: #556074;
				font-size: 1.5em;
				font-weight: 700;
				line-height: 1;
			}
			strong {
				margin-top: 2px;
				color: #3fa766;
				a {
					color: #3fa766;
					text-decoration: none;
					&:hover {
						color: #3fa766;
					}
				}
			}
		}
		.w-time-b {
			margin: 5px 0 0 54px;
			font-weight: 600;
			line-height: 1;			
			@extend %clearfix;			
			.g-icn-time {
				float: left;
				width: 13px;
				height: 13px;				
				margin: 1px 0 0 0;
				background: url("../images/icn-workingtime.png") 0 0 no-repeat;
			}
			p {
				float: left;
				margin: 1px 0 0 5px;
				color: #556074;
				font-size: 0.875em;
			}
			span {
				color: #fff;
				float: right;
				font-size: 0.75em;
				padding: 1px 5px 3px 5px;
				text-align: center;
				background: #3fa766;
			}
		}
	}
	.btn-menu {
		display: none;
		cursor: pointer;
		width: 40px;
		height: 40px;
		float: right;
		margin-top: 5px;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		@include transition(all 0.5s ease-in-out);
		&:before {
			color: #3a90d9;
			content: "Меню";
			display: block;
			position: absolute;
			right: 100%;
			top: 50%;
			margin: -13px 10px 0 0;
			font-size: 0.875em;
			font-weight: 600;
			text-transform: uppercase;
		}
		span {
			display: block;
			position: absolute;
			height: 6px;
			width: 100%;
			background: #3a90d9;
			@include border-radius(5px);	
			left: 0;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			@include opacity(1);
			@include transition(all 0.25s ease-in-out);
			&:nth-child(1) {
				top: 0px;
			}
			&:nth-child(2) {
			  top: 12px;
			}
			&:nth-child(3) {
			  top: 24px;
			}
		}
		&.g-active {
			span:nth-child(1) {
				top: 12px;
				-webkit-transform: rotate(135deg);
				-moz-transform: rotate(135deg);
				-o-transform: rotate(135deg);
				transform: rotate(135deg);
			}
			span:nth-child(2) {
				left: -120px;
				@include opacity(0);
			}
			span:nth-child(3) {
				top: 12px;
				-webkit-transform: rotate(-135deg);
				-moz-transform: rotate(-135deg);
				-o-transform: rotate(-135deg);
				transform: rotate(-135deg);
			}
		}
		&:hover {
			span {
				background: #4674cc;
			}
		}
	}
	@include breakpoint(large) {
		height: 85px;
		.g-logo {
			width: 170px;
			height: 25px;
			background-image: url("../images/logo-remont12-l.png");
			margin-top: 28px;
		}
		nav {
			left: 200px;
			right: 200px;
			top: 30px;
			li a {
				font-size: 0.875em;
				font-weight: 900;
			}
		}
		.w-contact-details-b {
			.w-phones-b {
				padding-left: 0;
				.g-icn-phone {
					display: none;
				}
				p,
				strong {
					font-size: 1.25em;
				}
			}		
			.w-time-b {
				margin-left: 0;
				p {
					font-size: 0.7em;
					margin-left: 4px;
				}
				span {
					padding: 1px 3px 3px 3px;
					font-size: 0.65em;
				}
			}
		}
	}
	@include breakpoint(medium) {
		height: auto;
		.w-header-b {		
			padding: 10px 0 5px 0;
			position: static;
			@include transition(all 0.55s ease-in-out);
			.g-logo {
				margin-top: 8px;				
			}
			.btn-menu {
				display: block;
			}
			nav {				
				top: auto;
				bottom: 15px;
				width: 100%;
				left: 0;
				right: 0;
				padding-top: 15px;
				@include opacity(0);
				visibility: hidden;
				@include transition(all 0.45s ease-in-out);
				background: url("../images/header-shadow.png") 0 0 repeat-x;
//				ul {
//					width: 170px;
//					display: block;
//					li {
//						display: block;
//					}
//				}
			}
			.w-contact-details-b {				
				position: static;
				float: right;
				margin: 0 80px 0 0;
				width: 170px;
    			text-align: center;
				.w-phones-b {
					.g-icn-phone {
						display: block;
						width: 30px;
						height: 30px;
						left: -40px;
					}
					p {
						display: none;
					}
					strong {
						margin-top: 0;
					}
				}
				.w-time-b {
					margin-top: 2px;
				}
			}
			&.g-opened {
				padding-bottom: 60px;
				nav {
					visibility: visible;
					border-top-color: #efefef;					
					@include opacity(1);
				}
			}
		}
	}
	@include breakpoint(small) {
		.w-header-b {
			.w-contact-details-b {
				
			}
		}
	}
	@include breakpoint(xsmall) {
		.w-header-b {
			.w-contact-details-b {
				display: none;
			}
			&.g-opened {
				padding-bottom: 185px;
			}
			nav {
				li {
					display: block;
					text-align: center;
					margin: 4px 0;
				}
			}
		}
	}
}

.w-intro-b {
	background: url("../images/intro-block-repeat-bg.jpg") 0 0 repeat-x;
	overflow: hidden;
	zoom: 1;
	.w-bg-b {
		background: url("../images/intro-block-bg.jpg") 50% 0 no-repeat;	
	}
	.l-wrapper {
		padding-top: 210px;
		position: relative;
		@include breakpoint(xsmall) {
			padding-top: 170px;
		}
	}
	.w-contact-request-form-b {
		width: 460px;
		color: #fff;
		margin: 0 auto;
		@include breakpoint(xsmall) {
			width: 95%;
		}
	}
	.w-advantages-b {
		padding: 290px 0 70px 0;
		h2 {
			color: #fff;
			font-size: 2em;
			font-weight: 600;
			display: block;
			text-align: center;
			margin-bottom: 40px;
		}
		.w-advantages-list-b {
			padding: 20px 0;
			display: table;
			width: 100%;
			@extend %clearfix;
			li {
				display: table-cell;
				width: 25%;
				position: relative;
				vertical-align: middle;
				.g-icn {
					display: block;
					width: 140px;
					height: 140px;
					margin: 0 auto;
					@include transition(all 0.35s ease-in-out);
					img {
						width: 100%;
						height: auto;
					}
				}
				.w-cont {
					vertical-align: middle;
					margin: 20px 70px 0 70px;
					text-align: center;
					p {
						color: #fff;
						font-size: 1.25em;
						line-height: 1.5;
						font-weight: 600;						
						text-transform: uppercase;
					}
				}
				&:hover {
					.g-icn {
						transform: scale(0.9);
					}
				}
			}
		}
		@include breakpoint(large) {
			.w-advantages-list-b {
				li {
					.w-cont {
						margin: 20px 40px 0 40px;
					}
				}
			}
		}
		@include breakpoint(medium) {
			h2 {
				font-size: 1.65em;
			}
			.w-advantages-list-b {
				li {
					.g-icn {
						width: 100px;
						height: 100px;
					}
					.w-cont {
						margin: 20px 30px 0 30px;
						p {
							font-size: 1em;
						}
					}
				}
			}
		}
		@include breakpoint(small) {
			padding: 250px 0 50px 0;
			h2 {
				margin-bottom: 0;
			}
			.w-advantages-list-b {
				padding: 0;
				display: block;
				li {
					margin-top: 40px;
					display: block;
					@include gallery(6 of 12);
					.g-icn {
						width: 110px;
						height: 110px;
					}
					.w-cont {
						margin: 10px 0 0;
						p {
							font-size: 0.9375em;
						}
					}
				}
			}
		}
		@include breakpoint(xsmall) {
			h2 {
				font-size: 1.25em;
				margin-bottom: 10px;
			}
			.w-advantages-list-b {
				display: block;
				li {
					margin-bottom: 20px;
					.g-icn {
						width: 90px;
						height: 90px;
					}
					.w-cont {
						margin: 10px 10px 0 10px;
						p {
							font-size: 0.9375em;
						}
					}
				}
				
			}
		}
	}
}

.w-contact-form-b {
	.w-inp-b {
		margin: 30px 0;
		position: relative;
		select {
			height: 40px;
			visibility: hidden;
		}
		.help-block {
			color: #fff;
			font-size: 0.725em;
			position: absolute;
			top: -20px;
			right: 0;
			padding: 1px 5px 3px 5px;
			@include border-radius(2px);
			&.form-error {						
				background-color: #ff5050;
				&:after {
					content: ' ';
					height: 0;
					position: absolute;
					top: 100%;
					right: 15px;
					width: 0;
					border: 10px solid rgba(0, 0, 0, 0);
					border-top-color: #ff5050;
					margin-top: -5px;
				}
			}
		}
		&.has-error {
			input[type="text"],
			.ms-options-wrap > button {
				border-color: #ff5050;
				background: #fff;					 
			}
		}
//				&.has-success {
//					input[type="text"],
//					.ms-options-wrap > button {
//						border-color: #53b076;
//						@include box-shadow(0,0,15px,#53b076);
//					}
//				}
	}	
	.w-action-b {
		margin-top: 30px;
		text-align: center;
	}
}

.w-contact-request-form-b {
	.w-contact-form-b {
		margin: 30px 60px 0 60px;
	}
	h1 {
		font-size: 1.675em;
		line-height: 1.5;
		font-weight: 800;
		padding: 0 20px;
		text-align: center;
	}
	p {
		margin-top: 20px;
		font-weight: 600;
		text-align: center;
	}
	@include breakpoint(xsmall) {
		width: 100%;
		h1 {
			font-size: 1.25em;
		}
		p {
			font-size: 0.875em;
		}
		.w-contact-form-b {
			margin: 30px auto;
			max-width: 360px;				
		}
	}
}

.w-intro-descr-b {	
	text-align: center;
	h3 {
		color: #3fa766;	
	}
	p {
		font-size: 1.25em;
		margin-top: 20px;
	}
	@include breakpoint(xsmall) {		
		p {
			font-size: 1em;
			margin-top: 10px;
		}
	}
}

.w-working-scheme-b {
	background: #3fa766 url("../images/howitworks-block-bg.jpg") 50% 100% no-repeat;
	overflow: hidden;
	zoom: 1;
	.w-scheme-list-b {
		display: block;
		padding: 20px 0;		
		@extend %clearfix;
		li {
			@include gallery(2 of 12);			
			position: relative;
			&:after {
				display: block;
				content: "";
				position: absolute;
				top: 55px;
				left: 100%;
				width: 17px;
				height: 28px;
				background: url("../images/icn-scheme-arr.png") 0 0 no-repeat;
			}
			.g-icn {
				display: block;
				width: 140px;
				height: 140px;
				margin: 0 auto;
				@include transition(all 0.35s ease-in-out);
				img {
					width: 100%;
					height: auto;
				}
			}
			.w-cont {
				padding: 30px 10px;
				text-align: center;
				p {
					color: #fff;
					font-size: 1.2em;
					line-height: 1.5;
					font-weight: 600;						
					text-transform: uppercase;
				}
			}
			&:hover {
				.g-icn {
					transform: scale(0.9);
				}
			}
			&.g-last {
				&:after {
					display: none;
				}
			}
		}
	}
	@include breakpoint(large) {
		.w-scheme-list-b {
			padding-top: 40px;
			li {
				&:after {
					top: 35px;
				}
				.g-icn {
					width: 100px;
					height: 100px;
				}
				.w-cont {
					padding: 20px 0;
					p {
						font-size: 1em;
					}
				}				
			}
		}
	}
	@include breakpoint(medium) {
		.w-scheme-list-b {
			padding-top: 0;
			li {
				@include gallery(4 of 12);
				margin-top: 40px;
				&:after {
					top: 35px;
				}
				.w-cont {
					padding: 20px 40px;
					p {
						font-size: 1em;
					}
				}
				&.g-3 {
					&:after {
						display: none;
					}
				}
			}
		}
	}
	@include breakpoint(small) {
		.w-scheme-list-b {
			padding-top: 0;
			li {
				@include gallery(6 of 12);
				margin-top: 30px;
				&:after {
					top: 35px;
				}
				.g-icn {
					width: 110px;
					height: 110px;
				}
				.w-cont {
					padding: 10px 40px;
					p {
						font-size: 1em;
					}
				} 
				&.g-3 {
					&:after {
						display: block;
					}
				}
				&.g-2, 
				&.g-4 {
					&:after {
						display: none;
					}
				}				
			}
		}
	}
	@include breakpoint(xsmall) {
		.w-scheme-list-b {
			padding-top: 0;
			li {
				margin-top: 40px;
				&:after {
					top: 25px;
					display: none;
				}
				&.g-1, 
				&.g-3,
				&.g-5 {
					&:after {
						display: block;
					}
				}
				.g-icn {
					width: 80px;
					height: 80px;
				}
				.w-cont {
					padding: 10px 0;
					br {
						display: none;
					}
				}				
			}
		}
	}
}

.w-prices-b {
	max-height: 890px;
	background: #4673ca url("../images/prices-block-bg.jpg") 50% 0 no-repeat;
	.w-prices-tbl {		
		margin: 0 100px;
		table {
			font-size: 1.375em;
			border-collapse: separate;
			border-spacing: 0 5px;
			width: 100%;
			tr {
				th {
					color: #fff;
					text-align: left;
					background-color: #3fa766;
					padding: 4px 30px;
				}
				td {
					text-align: left;
					background-color: #fff;
					padding: 4px 30px;
					@include transition(background 0.15s);					
				}
				&:nth-child(odd) {
					td {
						background-color: #e8eeff;
					}
				}
				&:hover {
					td {
						background-color: #f5f5f5;
					}
					&:nth-child(odd) {
						td {
							background-color: #e1e7f7;
						}
					}					
				}
			}
		}
	}
	.w-note-b {
		color: #fff;
		margin: 30px 130px 0 130px;
		p {
			color: #fff;
			font-size: 0.8175em;
			margin-top: 3px;
		}
	}
	@include breakpoint(medium) {
		.w-prices-tbl {		
			margin: 0;
		}
		.w-note-b {
			margin: 30px 0 0;
		}
	}
	@include breakpoint(small) {
		.w-prices-tbl {
			table {
				font-size: 1em;
				tr {
					th,
					td {
						padding: 2px 15px;				
					}
				}
			}
		}
	}
	@include breakpoint(xsmall) {
		.w-prices-tbl {
			table {
				font-size: 0.9375em;
				tr {
					th,
					td {
						padding: 2px 10px;				
					}
				}
			}
		}
		.w-note-b {
			p {
				font-size: 0.675em;
			}
		}
	}
}

.w-feedbacks-b {
	background: #3fa766 url("../images/feedbacks-block-bg.jpg") 50% 100% no-repeat;
	h2 {
		color: #fff;
		margin-bottom: 0;
	}
	h5 {
		color: #fff;
		text-align: center;
	}
	.w-feedbacks-cont-b {
		position: relative;
		overflow: hidden;
		zoom: 1;		
		&:before {
			display: block;
			content: "";
			width: 50px;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: -moz-linear-gradient(left, rgba(63,168,102,1) 0%, rgba(63,168,102,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(63,168,102,1) 0%,rgba(63,168,102,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(63,168,102,1) 0%,rgba(63,168,102,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3fa866', endColorstr='#003fa866',GradientType=1 ); /* IE6-9 */
			z-index: 10;
		}
		&:after {
			display: block;
			content: "";
			width: 50px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;								
			background: -moz-linear-gradient(left, rgba(63,168,102,0) 0%, rgba(63,168,102,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(63,168,102,0) 0%,rgba(63,168,102,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(63,168,102,0) 0%,rgba(63,168,102,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003fa866', endColorstr='#3fa866',GradientType=1 ); /* IE6-9 */
			z-index: 10;
		}
		.strong-view {
			.strong-content {				
				margin: 70px 0 30px 0;
			}
			.testimonial-inner {				
				border: none;
				padding: 30px;
				position: relative;
				background: #fff;
				@include breakpoint(small) {
					padding: 30px 15px;
				}
			}
			.testimonial {		
				margin: 0;
				border: none;
				.testimonial-content {
					border-top: 1px solid #e7e7e7;
					margin-top: 125px;
					padding-top: 25px;
					text-align: center;
				}
				.testimonial-client {
					width: 100%;
					position: absolute;
					top: 50px;
					left: 0;
					text-align: center;
					.testimonial-name {
						font-weight: 600;
						margin-bottom: 5px;
					}
					.date {
						color: #8d8d8d;
						font-size: 0.9375em;
					}
				}
				.testimonial-image {
					position: absolute;
					top: -45px;
					left: 50%;
					margin-left: -45px;
					border: none;
					@include border-radius(50%);
					overflow: hidden;
					zoom: 1;
					img {
						background: #3fa766;
						border: none;
						padding: 5px;
						@include border-radius(50%);
						overflow: hidden;
						zoom: 1;
					}
				}
			}									
			.g-slider {				
				.testimonial {
					float: left;
					margin: 0 30px;
					@include breakpoint(small) {
						margin: 0 10px;
					}
				}
			}
		}
		.slick-arrow {
			display: block;
			cursor: pointer;
			width: 38px;
			height: 38px;
			border: none;
			position: absolute;
			top: 50%;
			left: 32px;
			margin-top: -19px;			
			@include border-radius(5px);
			@include opacity(0.95);
			text-indent: -99999em;
			@include transition(all 0.15s ease-in-out);
			background: #586378 url("../images/icns-slider-nav.png") -32px 0 no-repeat;
			border: 3px solid #586378;
			z-index: 99;
			&:hover {
				@include opacity(1);
			}
			&.slick-next {
				left: auto;
				right: 32px;
				background-position: 0 0;
			}
		}
	}
	.w-feedbacks-action-b {
		text-align: center;
		color: #fff;
		margin-top: 30px;
		.w-action-b {
			margin-top: 30px;
		}
	}
}

.w-faq-b {
	padding-bottom: 0;
	h2 {
		color: #2e3239;
	}
	.w-faq-list-b {
		.w-item-b {
			position: relative;
			padding: 30px 30px 30px 45px;
			border-bottom: 1px solid #e7e7e7;
			.g-icn {
				display: block;
				width: 32px;
				height: 32px;
				position: absolute;
				top: 25px;
				left: 0;
				img {
					width: 100%;
					margin: 0;
				}
			}
			h4 {
				display: block;
				color: #3c5c9c;
				font-size: 1.25em;
				font-weight: 600;
			}
			p {
				margin-top: 15px;
			}
			.w-pic-b {
				margin-top: 20px;
			}
			&.g-green {
				h4 {
					color: #23944d;
				}
			}
			&:last-of-type {
				border-bottom: none;
				padding-bottom: 0;
			}
		}
		@include breakpoint(small) {
			.w-item-b {
				padding: 30px 0;
				h4 {
					font-size: 1.15em;
				}
				.g-icn {
					display: none;
				}
				p {
					font-size: 0.9375em;
					margin-top: 10px;
				}
				.w-pic-b {
					display: none;
				}
			}
		}
		@include breakpoint(xsmall) {
			.w-item-b {
				padding: 30px 0;
				h4 {
					font-size: 1em;
				}
			}
		}
	}
}

footer {
	.w-vk-widget-b {
		background: #586378;
		@extend %clearfix;
		padding: 50px 0;
		min-height: 400px;
		position: relative;
		&:after {
			display: block;
			content: "";
			position: absolute;
			bottom: -5px;
			left: 0;
			height: 10px;
			width: 100%;
			background: url("../images/header-shadow.png") repeat-x;
		}
	}
    .advert-link {
        color: #fff;
        margin-top: 30px;
        font-size: 12px;
        text-align: center;
        a {
            color: #fff;
        }
    }
	.w-contact-info-b {
		color: #fff;
		text-align: center;
		padding: 50px 0;
		max-height: 270px;		
		background: #3a71ce url("../images/footer-contact-block-bg.jpg") 50% 0 no-repeat;
		a {
			color: #fff;
			text-decoration: none;
			&:hover {
				color: #fff;
			}
		}
		h3 {
			font-size: 1.5em;		
			text-transform: none;
			b {
				display: block;
    			font-size: 1.4em;
   			 	margin-top: 5px;
			}
		}
		p {
			margin: 10px 0 20px 0;
		}
	}
	.w-footer-line {
		background: #586378;
		@extend %clearfix;
		padding: 50px 0 70px 0;
		position: relative;
		&:after {
			display: block;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 10px;
			width: 100%;
			background: url("../images/header-shadow.png") repeat-x;
		}
		.w-menu {
			float: left;
			@extend %clearfix;
			li {
				float: left;
				text-align: center;
				padding-right: 30px;
				a {
					display: inline-block;
					color: #fff;
					font-weight: 700;					
					text-transform: uppercase;
					text-decoration: none;
					padding: 2px;
					background: transparent;
					position: relative;
					&:after {
						content: '';
						display: block;
						margin: 0 auto;
						height: 2px;
						width: 0px;
						background: transparent;
						@include transition(all 0.25s);
					}
					&:hover {
						text-decoration: none;
						&:after {
							width: 100%;
							background: #fff;
						}
					}
				}
			}
		}
		.w-copyright {
			float: right;
			padding-top: 2px;
			p {
				color: #fff;
			}	
		}
	}
	@include breakpoint(large) {
		.w-footer-line {
			.w-menu li a {			
				font-size: 0.875em;
			}
			.w-copyright {
				font-size: 0.875em;
			}
		}
	}
	@include breakpoint(medium) {
		.w-footer-line {
			.w-menu,
			.w-copyright {
				display: block;
				width: 100%;
				float: none;
				text-align: center;				
			}
			.w-menu {
				display: table;
				li {
					width: 20%;
					padding: 0;
					display: table-cell;
					text-align: center;					
					a {
						font-size: 0.875em;
					}
				}
			}
			.w-copyright {
				margin-top: 20px;
			}
		}
	}
	@include breakpoint(xsmall) {		
		.w-contact-info-b {
			h3 {
				font-size: 1.25em;
				b {
					font-size: 1.45em;
				}
			}
		}
		.w-footer-line {			
			.w-menu {				
				display: block;				
				li {
					display: block;
					width: 100%;
					text-align: center;
					margin: 4px 0;
				}
			}
		}
	}
}

.w-share-b {
	@extend %clearfix;
	padding: 60px 0;	
	h3 {
		float: left;
		color: #556074;
		font-size: 1em;
		padding-right: 10px;
		text-transform: none;
		font-weight: normal;
	}
	.w-yandex-share-b {
		float: left;
	}
}

.btn-scroll-top {		
	position: fixed;
	bottom: 30px;
	right: 30px;
	padding: 10px;
	@include border-radius(5px);
	background: #586378;
	z-index: 10;
	@include opacity(0);
	@include transition(opacity 0.65s ease-in-out);
	visibility: hidden;
	span {
		display: block;
		width: 32px;
		height: 32px;
		@include transition(opacity 0.15s);
	}
	&:hover {
		span {
			@include opacity(0.8);
		}
	}
	@include breakpoint(medium) {
		bottom: 20px;
		right: 20px;
		span {
			width: 22px;
			height: 22px;
		}
	}
	&.g-shown {
		@include opacity(1);
		visibility: visible;
	}
	@include breakpoint(small) {
		display: none;
		&.g-shown {			
			@include opacity(0);
			visibility: hidden;
		}
	}
}

.btn-call-action {
	position: fixed; 
	display: none;
	width: 42px;
	height: 42px;
	@include border-radius(50%);
	z-index: 11;
	bottom: 20px;
	right: 20px;
	border: 2px solid #40ae67;
	box-shadow: 0 .125em .5em #0b6f31, inset 0 0 0 3em #3fa766, 0 0 0 0 rgba( #3fa766, .25 );
  	background-color: #3fa766;
  	animation: scaleUp 2s infinite, borderScale 2s infinite;
  	> svg {
    	width : 30px;
    	height : 30px;
		margin: 4px;
    	fill : #fff;
    	animation : shake 2s cubic-bezier( 0.43, 0, 0.6, 0.4 ) infinite;
  	}
	@include breakpoint(small) {
		display: block;		
	}
}

@keyframes borderScale {
  0% {
      box-shadow :
    0 .125em .5em #0b6f31,
    inset 0 0 0 3em #3fa766,
    0 0 0 0 rgba( #3fa766, .25 );
  }
  
  50% {
      box-shadow :
    0 .125em .5em #0b6f31,
    inset 0 0 0 .5em #3fa766,
    0 0 0 0 rgba( #3fa766, .25 );
  }
  
  100% {
      box-shadow :
    0 .125em .5em #0b6f31,
    inset 0 0 0 3em #3fa766,
    0 0 0 3em rgba( #3fa766, 0 );
  }
}

@keyframes scaleUp {
  0% {
    transform : scale( 1 );
  }
  
  50% {
    transform : scale( 1.125 );
  }
  60% {
    transform : scale( 1 );
  }
}

@keyframes shake {
  0% {
    transform : translate( 0, 0 )
  }
  1.25% {
    transform : translate( -2.5%, 0 )
  }
  2.5% {
    transform : translate( 0,0 );
  }
  3.75% {
    transform : translate( 2.5%, 0 );
  }
  5% {
    transform : translate( 0, 0 );
  }
  6.25% {
    transform : translate( -5%, 0 )
  }
  7.5% {
    transform : translate( 0,0 );
  }
  8.75% {
    transform : translate( 5%, 0 );
  }
  10% {
    transform : translate( 0, 0 );
  }
  11.25% {
    transform : translate( -5%, 0 )
  }
  12.5% {
    transform : translate( 0,0 );
  }
  13.75% {
    transform : translate( 5%, 0 );
  }
  15% {
    transform : translate( 0, 0 );
  }
  16.25% {
    transform : translate( -7.5%, 0 )
  }
  17.5% {
    transform : translate( 0,0 );
  }
  18.75% {
    transform : translate( 7.5%, 0 );
  }
  20% {
    transform : translate( 0, 0 );
  }
  21.25% {
    transform : translate( -7.5%, 0 )
  }
  22.5% {
    transform : translate( 0,0 );
  }
  23.75% {
    transform : translate( 7.5%, 0 );
  }
  25% {
    transform : translate( 0, 0 );
  }
  26.25% {
    transform : translate( -7.5%, 0 )
  }
  27.5% {
    transform : translate( 0,0 );
  }
  28.75% {
    transform : translate( 7.5%, 0 );
  }
  30% {
    transform : translate( 0, 0 );
  }
  31.25% {
    transform : translate( -7.5%, 0 )
  }
  32.5% {
    transform : translate( 0,0 );
  }
  33.75% {
    transform : translate( 7.5%, 0 );
  }
  35% {
    transform : translate( 0, 0 );
  }
  36.25% {
    transform : translate( -7.5%, 0 )
  }
  37.5% {
    transform : translate( 0,0 );
  }
  38.75% {
    transform : translate( 7.5%, 0 );
  }
  40% {
    transform : translate( 0, 0 );
  }
  41.25% {
    transform : translate( -7.5%, 0 )
  }
  42.5% {
    transform : translate( 0,0 );
  }
  43.75% {
    transform : translate( 7.5%, 0 );
  }
  45% {
    transform : translate( 0, 0 );
  }
  46.25% {
    transform : translate( -7.5%, 0 )
  }
  47.5% {
    transform : translate( 0,0 );
  }
  48.75% {
    transform : translate( 7.5%, 0 );
  }
  50% {
    transform : translate( 0, 0 );
  }
}

.w-404-b {
	text-align: center;
	padding: 200px 0 170px 0;
	height: 100%;
	h2 {
		position: relative;
		margin: 30px auto;
		span {
			display: block;
			width: 180px;
			height: 115px;
			text-align: center;
			color: #fff;
			font-size: 3em;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -56px 0 0 -90px;
		}		
		background: #586378;
		width: 200px;
		height: 200px;
		@include border-radius(100%);
	}
	.w-404-descr-b {
		p {
			margin-top: 10px;
		}
	}
}

.w-popup {
	color: #2e3239;
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 1000;
	@include opacity(0);
	@include transition(all 0.15s ease-in-out);
	.w-content-b {
		background: #fff;
		position: relative;
		padding: 40px;
		@include border-radius(3px);
		@include transition(all 0.25s);
		.btn-close {
			display: block;
			width: 22px;
			height: 22px;
			position: absolute;
			top: -20px;
			right: -40px;
			background: url("../images/icn-close-popup.png") -22px 0 no-repeat;
			@include opacity(0.5);
			@include transition(opacity 0.25s);
			&:hover {
				@include opacity(1);
			}
		}
		input[type="text"],
		input[type="email"],
		textarea {		
			background: #fff;
			padding: 9.5px 15px;
			border: 1px solid #a0acc1;
			background: #fefefe;
			@include box-shadow(inset,0,2px,2px,rgba(0,0,0,0.1));		
		}
		.ms-options-wrap > button:focus,
		.ms-options-wrap > button {
			border: 1px solid #a0acc1;
		}
		h5 {
			display: block;
			text-align: center;
			margin-bottom: 20px;		
			font-size: 2em;
			font-weight: 600;
		}
		p {
			font-weight: 600;
		}
	}
	&.g-success-popup {
		.w-content-b {
			text-align: center;
			padding: 60px 120px;
			h5 {
				color: #3fa766;
			}
		}
	}
	&.g-error-popup {
		.w-content-b {
			text-align: center;
			padding: 60px 120px;
			h5 {
				color: #ff5050;
			}
		}
	}
	&.g-contact-popup {
		.w-content-b {
			width: 400px;
		}
		.w-contact-form-b {			
			.w-inp-b {
				margin: 0 0 30px 0;
			}
		}
	}
	&.g-feedback-popup {
		.w-content-b {
			width: 400px;
		}
		.w-feedbacks-form-b {			
			.strong-form-inner {
				padding: 0;				
			}
			.testimonial-success {
				text-align: center;
				padding-top: 130px;
				height: 375px;
				h5 {
					color: #3fa766;
				}
			}
			.form-field {
				position: relative;
				margin-bottom: 30px;
				&.submit {
					margin-bottom: 0;
					height: 45px;
				}
				label {
					color: #586378;
					font-size: 0.875em;
					font-weight: 600;
					line-height: 1.25;
					margin-bottom: 3px;
				}
				label.error {
					color: #fff;
					font-size: 0.725em;
					font-weight: 400;
					position: absolute;
					top: -19px;
					right: 0;
					padding: 1px 5px 3px 5px;
					@include border-radius(2px);					
					background-color: #ff5050;
					&:after {
						content: ' ';
						height: 0;
						position: absolute;
						top: 100%;
						right: 15px;
						width: 0;
						border: 10px solid rgba(0, 0, 0, 0);
						border-top-color: #ff5050;
						margin-top: -5px;
					}
				}
				.required {
					display: none;
					font-size: 0.85em;
					font-weight: 600;
					&:after {
						position: absolute;
						top: 0;
					}
				}
				input,
				textarea {
					&.error {
						border-color: #ff5050;
						background: #fff;
					}
				}
				input[type="file"] {
					margin-top: 3px;
				}
				textarea {
					height: 125px;
					resize: none;
				}
				.strong-rating {
					margin-left: -3px;
					label {
						margin: 0 0 0 -1px;
						font-size: 1.15em;
					}
				}
			}
		}
	}
	@include breakpoint(xsmall) {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		margin: 0 !important;
		.w-content-b {
			width: 100% !important;
			height: 100%;
			@include border-radius(0);
			padding-top: 70px;
			.btn-close {		
				background-position: 0 0;
				top: 20px;
				right: 40px;
			}
		}
	}	
}